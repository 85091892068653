import React from 'react';
import InputSelect from '../../atoms/InputSelect';
import InputSelectTable from '../../atoms/InputSelectTable';

const tipos = [
    { key: 'Em Aberto', value: 'Em Aberto' },
    { key: 'Pago', value: 'Pago' },
];

function InputSelectTipoPagamento(props) {
    return (
        <>
            {props.editable &&
                <InputSelectTable {...props} options={tipos} />
            }
            {!props.editable &&
                <InputSelect {...props} options={tipos} />
            }
        </>
    );
}

export default InputSelectTipoPagamento;