import React, { useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';

function ButtonFile(props) {
    const hiddenFileInput = useRef(null); 

    const handleClick = () => {
        hiddenFileInput.current.click();   
    };

    const handleChange = async (event) => {
        props.onFileLoad(event.target.files[0]);
    };
    
    return (
        <>
            <Form.Group controlId={props.name} className="">
                <Button className="float-right" variant="secondary"
                    onClick={handleClick}>
                    <FaPlusCircle />
                  </Button>
                <Form.Control name={props.id} type="file" ref={hiddenFileInput} style={{cursor: 'pointer', display: 'none'}} onChange={handleChange} disabled={props.disabled} required={props.required} />
            </Form.Group>
        </>
    );
}

export default ButtonFile;