import React from 'react';
import { Form } from 'react-bootstrap';
import RequiredField from '../RequiredField';

function InputTextArea(props) {
    return (
        <>
            <Form.Group>
                <Form.Label>{props.name}{props.required ? <RequiredField /> : ''}</Form.Label>
                <Form.Control as="textarea" rows={props.rows} name={props.id} placeholder={props.disabled ? '' : `Inserir ${props.name}`}
                    disabled={props.disabled} onChange={props.onChange} value={props.value} required={props.required} />
                <Form.Control.Feedback type="invalid">
                    Campo {props.name} inválido
                </Form.Control.Feedback>
                {
                    props.additionalText &&
                    <Form.Text className="text-muted">
                        {props.additionalText}
                    </Form.Text>
                }
            </Form.Group>
        </>
    );
}

InputTextArea.defaultProps = {
    rows: '3'
}

export default InputTextArea;