import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { formatMoney, formatDate, formatTime, formatDayName } from '../../../helpers/functions';
import VerticalSpace from '../../../components/atoms/VerticalSpace';

function getTasksFormateds(tasks) {
  let textFormated = '';
  tasks.map((task, i) => {
    if (i > 0) {
      textFormated += '___________________________________';
      textFormated += '\r\n';
    }

    const dataInicio = formatDate(task.dataHoraInicio);
    const dataFim = formatDate(task.dataHoraFim);
    const timeInicio = formatTime(task.dataHoraInicio);
    const timeFim = formatTime(task.dataHoraFim);
    const dayNameInicio = formatDayName(task.dataHoraInicio);
    const dayNameFim = formatDayName(task.dataHoraFim);

    if (dataInicio === dataFim) {
      textFormated += `${dataInicio} (${dayNameInicio}) ${timeInicio} - ${timeFim}`;
    } else {
      textFormated += `${dataInicio} (${dayNameInicio}) ${timeInicio}`;
      textFormated += '\r\n';
      textFormated += `${dataFim} (${dayNameFim}) ${timeFim}`;
    }
    textFormated += '\r\n';

    textFormated += `Evento: ${task.tipo} (${task.status})`;
    textFormated += '\r\n';

    textFormated += `Nome: ${task.nome}`;
    textFormated += '\r\n';

    if (task.telefone) {
      textFormated += `Telefone: ${task.telefone}`;
      textFormated += '\r\n';
    }

    if (task.endereco) {
      textFormated += `Endereço: ${task.endereco}`;
      textFormated += '\r\n';
    }

    if (task.valorTotal > 0) {
      textFormated += `Valor Total: ${formatMoney(task.valorTotal)} `;
      textFormated += task.totalRestante > 0 ? `Resta: ${formatMoney(task.totalRestante)}` : `100% Pago`;
      textFormated += '\r\n';
    }

    if (task.observacoes) {
      textFormated += `Notas: ${task.observacoes}`;
      textFormated += '\r\n';
    }

    textFormated += `+Detalhes: http://agenda.hectech.com.br/task/detail/${task._id}`;
    textFormated += '\r\n';
  });

  return textFormated;
}

function ExportTasks(props) {

  const [copied, setCopied] = useState(false);

  const copyInfo = () => {
    const text = document.getElementById('infos').textContent;
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  let tasks = props.tasks.sort((a, b) => ((a.dataHoraInicio > b.dataHoraInicio) && 1) || -1);

  return (
    <div style={{ marginLeft: '20px', marginRight: '20px' }}>
      <Row>
        <Col>
          <Button variant="primary" onClick={copyInfo}>Copiar</Button>
        </Col>
        <Col>
          {copied && <p style={{color: 'green'}}>Texto Copiado!</p>}
        </Col>
      </Row>
      <VerticalSpace />
      <Form.Group>
        <Form.Control id="infos" as="textarea" disabled rows={20} value={getTasksFormateds(tasks)} />
      </Form.Group>
    </div>
  );
}

export default ExportTasks;