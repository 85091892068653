import { useState } from 'react';

const useForm = () => {
    const [inputs, setInputs] = useState({});

    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
    }

    const handleInputChangeMultiple = (event) => {
        event.persist();
        let result = [];
        let options = event.target && event.target.options;
        let opt;

        for (let i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }

        setInputs(inputs => ({ ...inputs, [event.target.name]: result }));
    }

    const validateInputs = (others) => {
        let errors = [];

        if (inputs) {
            if (others) {
                if (others.dataHoraInicio && others.dataHoraFim) {
                    if (others.dataHoraFim <= others.dataHoraInicio) {
                        errors.push('O Fim do compromisso não pode ser anterior ou igual ao Início.');
                    }
                }

                if (inputs.pagamentos && inputs.pagamentos.length > 0) {
                    let semForma = 0;
                    inputs.pagamentos.forEach(pagamento => {
                        if (pagamento.valor) {
                            if (!pagamento.formaPagamento || pagamento.formaPagamento === '') {
                                semForma += 1;
                            }
                        }
                    });

                    if (semForma > 0) {
                        errors.push('A Forma de Pagamento precisa ser definida.');
                    }
                }

                if (others.page === 'create') {
                    if (others.dataHoraInicio < new Date()) {
                        errors.push('O Início do compromisso não pode ser no passado.');
                    }
                }

                if (others.page === 'edit') {
                    if (others.dataHoraInicio > new Date()
                        && inputs.status === 'Realizado') {
                        errors.push('Um compromisso futuro não pode ter Status Realizado.');
                    }

                    if (others.dataHoraInicio < new Date()
                        && inputs.status === 'Agendado') {
                        errors.push('Um compromisso passado não pode ter Status Agendado.');
                    }

                    if (others.dataHoraInicio < new Date()
                        && inputs.status === 'Reservado') {
                        errors.push('Um compromisso passado não pode ter Status Reservado.');
                    }
                }

                if (others.page === 'profile') {
                    if (inputs.senha?.length < 8 || inputs.senhaConfirmacao?.length < 8) {
                        errors.push('A senha deve ter pelo menos 8 caracteres.');
                    }

                    if (inputs.senha !== inputs.senhaConfirmacao) {
                        errors.push('As senhas informadas não são correspondentes.');
                    }
                }
            }
        }

        return errors;
    }

    return {
        handleInputChange,
        handleInputChangeMultiple,
        inputs,
        setInputs,
        validateInputs
    };
}

export default useForm;
