import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import Session from '../../../components/atoms/Session';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ViewTotalPayments from '../../../components/atoms/ViewTotalPayments';
import { FaPlusCircle } from 'react-icons/fa';
import { optionsFormatterRemoveFunction } from '../../../helpers/tableFunctions';
import { getConfigCompany } from '../../../helpers/authHelper';
import { formatDate, formatMoney, convertConfigToBoolean } from '../../../helpers/functions';
import Table from '../../../components/atoms/Table';
import InputSelectFormaPagamento from '../../../components/molecules/InputSelectFormaPagamento';
import InputSelectTipoPagamento from '../../../components/molecules/InputSelectTipoPagamento';
import InputMoneyTable from '../../../components/atoms/InputMoneyTable';
import InputDateTable from '../../../components/atoms/InputDateTable';

function PaymentSession(props) {

  const pagamentoHabilitado = convertConfigToBoolean(getConfigCompany("PAGAMENTO_HABILITADO"));
  const [changePayments, setChangePayments] = useState(0);

  const removePayment = async (ident) => {
    props.setLoading(true);
    let payments = [];
    await props.inputs.pagamentos.forEach((pagamento) => {
      if (pagamento && pagamento.ident !== ident) {
        payments.push(pagamento);
      }
    });
    props.setInputs({ ...props.inputs, pagamentos: payments });
    props.setLoading(false);
  };

  let paymentsColumn = [
    {
      dataField: 'ident', hidden: true
    },
    {
      dataField: 'tipo', text: 'Status', sort: true,
      editorRenderer: (ep, value) => (<InputSelectTipoPagamento editable={true} {...ep} value={value} />)
    },
    {
      dataField: 'formaPagamento', text: 'Forma', sort: true,
      editorRenderer: (ep, value) => (<InputSelectFormaPagamento editable={true} {...ep} value={value} />)
    },
    {
      dataField: 'valor', text: 'Valor', sort: true,
      formatter: (cell) => (<>{formatMoney(cell)}</>),
      editorRenderer: (editorProps, value) => (<InputMoneyTable {...editorProps} value={value} />)
    },
    {
      dataField: 'data', text: 'Data', sort: true,
      formatter: (cell) => (<>{formatDate(cell)}</>),
      editorRenderer: (editorProps, value) => (<InputDateTable {...editorProps} value={value} />)
    }
  ];

  if (props.editable) {
    paymentsColumn.push({
      dataField: '_id', text: 'Opções', 
      formatter: (cel, row) => (<>{optionsFormatterRemoveFunction(() => { removePayment(row.ident) })}</>),
      editable: false
    });
  }

  return (
    <>
      {pagamentoHabilitado &&
        <>
          <Session name="Pagamentos">
            <Row>
              <Col xs={8}>
                <ViewTotalPayments pagamentos={props.inputs.pagamentos} changePayments={changePayments} />
              </Col>
              <Col>
                {props.editable &&
                  <Button className="float-right" variant="secondary"
                    onClick={() => props.setInsertPaymentModalShow(true)}>
                    <FaPlusCircle />
                  </Button>
                }
              </Col>
            </Row>
            <Row>
              <Col>
                <Table data={props.inputs.pagamentos || []} columns={paymentsColumn} keyField='ident'
                  cellEdit={props.editable ? cellEditFactory({
                    mode: 'click', blurToSave: true,
                    afterSaveCell: () => setChangePayments(changePayments + 1)
                  }) : false}
                  defaultSorted={[{ dataField: 'data', order: 'asc' }]} />
              </Col>
            </Row>
          </Session>
        </>
      }
    </>
  );
}

export default PaymentSession;