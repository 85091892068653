import React from 'react';
import { Form } from 'react-bootstrap';

function InputSelectTable(props) {

    const onChangeFunc = (event) => {
        const value = event && event.target ? event.target.value : props.value;
        props.onUpdate(value);
    };

    return (
        <>
            <Form.Group style={{ marginBottom: 0 }}>
                <Form.Control as="select" onChange={onChangeFunc} onBlur={onChangeFunc}
                    autoFocus={true} value={props.value} 
                    required={props.required} disabled={props.disabled}>
                    {
                        props.options.sort((a, b) => ((a.value > b.value) && 1) || -1).map(opt => (
                            <option key={opt.key} value={opt.key}>{opt.value}</option>
                        ))
                    }
                </Form.Control>
            </Form.Group>
        </>
    );
}

export default InputSelectTable;