import React, { useState, useEffect } from 'react';
import { searchTasks } from '../../../services/Tasks';
import Template from '../../../components/organisms/Template';
import { Row, Button, ButtonGroup, Container } from 'react-bootstrap';
import { FaSync, FaColumns } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import Table from '../../../components/atoms/Table';
import { formatDateTime, formatMoney, convertConfigToBoolean } from '../../../helpers/functions';
import { optionsFormatterDetail, rowFormatter } from '../../../helpers/tableFunctions';
import PageHeader from '../../../components/molecules/PageHeader';
import ModalSelectColumns from '../../../components/organisms/ModalSelectColumns';
import { getConfigCompany } from '../../../helpers/authHelper';

function TasksDeleted() {

  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await searchTasks({ deleted: true }, alert);
      if (result) {
        setTasks(result);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const refresh = async () => {
    setLoading(true);
    const result = await searchTasks({ deleted: true }, alert);
    if (result) {
      setTasks(result);
      alert.success('Lista atualizada');
    }
    setLoading(false);
  };

  let initialColumnsProps = {
    tipo: { name: 'Tipo', visibility: true },
    dataHoraInicio: { name: 'Início', visibility: true },
    dataHoraFim: { name: 'Fim', visibility: false },
    nome: { name: 'Nome', visibility: true },
    telefone: { name: 'Telefone', visibility: true },
    endereco: { name: 'Endereço', visibility: true },
    status: { name: 'Status', visibility: true },
    statusPagamento: { name: 'Status Pagamento', visibility: false },
    valorTotal: { name: 'Valor Total', visibility: false },
    totalPago: { name: 'Valor Pago', visibility: false },
    totalRestante: { name: 'Valor Restante', visibility: false },
    responsavel: { name: 'Responsável', visibility: false },
  };

  const [columnsProps, setColumnsProps] = useState(initialColumnsProps);
  const [selectColumnsModalShow, setSelectColumnsModalShow] = useState(false);

  let tasksColumn = [
    {
      dataField: '_id', text: '#',
      formatter: (cell, row, rowIndex) => (<>{rowFormatter(rowIndex, tasks, row)}</>)
    },
    {
      dataField: 'tipo', text: columnsProps['tipo'].name, sort: true,
      hidden: !columnsProps['tipo'].visibility,
    },
    {
      dataField: 'dataHoraInicio', text: columnsProps['dataHoraInicio'].name, sort: true,
      hidden: !columnsProps['dataHoraInicio'].visibility,
      formatter: (cell) => (<>{formatDateTime(cell)}</>),
    },
    {
      dataField: 'dataHoraFim', text: columnsProps['dataHoraFim'].name, sort: true,
      hidden: !columnsProps['dataHoraFim'].visibility,
      formatter: (cell) => (<>{formatDateTime(cell)}</>),
    },
    {
      dataField: 'nome', text: columnsProps['nome'].name, sort: true,
      hidden: !columnsProps['nome'].visibility,
    },
    {
      dataField: 'telefone', text: columnsProps['telefone'].name, sort: true,
      hidden: !columnsProps['telefone'].visibility,
    },
    {
      dataField: 'endereco', text: columnsProps['endereco'].name, sort: true,
      hidden: !columnsProps['endereco'].visibility,
    },
    {
      dataField: 'status', text: columnsProps['status'].name, sort: true,
      hidden: !columnsProps['status'].visibility,
    },
    {
      dataField: 'statusPagamento', text: columnsProps['statusPagamento']?.name, sort: true,
      hidden: !columnsProps['statusPagamento']?.visibility,
    },
    {
      dataField: 'valorTotal', text: columnsProps['valorTotal']?.name, sort: true,
      hidden: !columnsProps['valorTotal']?.visibility,
      formatter: (cell) => (<>{formatMoney(cell)}</>),
    },
    {
      dataField: 'totalPago', text: columnsProps['totalPago']?.name, sort: true,
      hidden: !columnsProps['totalPago']?.visibility,
      formatter: (cell) => (<>{formatMoney(cell)}</>),
    },
    {
      dataField: 'totalRestante', text: columnsProps['totalRestante']?.name, sort: true,
      hidden: !columnsProps['totalRestante']?.visibility,
      formatter: (cell) => (<>{formatMoney(cell)}</>),
    },
    {
      dataField: 'responsavel.nome', text: columnsProps['responsavel'].name, sort: true,
      hidden: !columnsProps['responsavel'].visibility,
    },
    {
      dataField: '_id', text: 'Opções',
      formatter: (cell) => (<>{optionsFormatterDetail(cell, 'task')}</>),
    }
  ];

  const pagamentoHabilitado = convertConfigToBoolean(getConfigCompany("PAGAMENTO_HABILITADO"));
  if (!pagamentoHabilitado) {
    tasksColumn = tasksColumn.filter(obj => (!['statusPagamento', 'valorTotal', 'totalPago', 'totalRestante'].includes(obj.dataField)));

    delete initialColumnsProps.statusPagamento;
    delete initialColumnsProps.valorTotal;
    delete initialColumnsProps.totalPago;
    delete initialColumnsProps.totalRestante;
  }

  return (
    <Template loading={loading} onlyContent>
      <PageHeader tittle="Compromissos Removidos" min>
        <ButtonGroup className="float-right">
          <Button variant="primary" onClick={() => refresh()}>
            <FaSync />
          </Button>
          <Button variant="secondary" onClick={() => setSelectColumnsModalShow(true)}>
            <FaColumns />
          </Button>
        </ButtonGroup>
      </PageHeader>
      <Row>
        <Container className="container container-nobars">
          <Table data={tasks} columns={tasksColumn} keyField='_id'
            defaultSorted={[{ dataField: 'dataHoraInicio', order: 'asc' }]} />
        </Container>
      </Row>
      <ModalSelectColumns show={selectColumnsModalShow} columnsProps={columnsProps}
        setColumnsProps={setColumnsProps} initialColumnsProps={initialColumnsProps}
        onHide={() => setSelectColumnsModalShow(false)} />
    </Template>
  );
}

export default TasksDeleted;