import React from 'react';
import { Form } from 'react-bootstrap';
import RequiredField from '../RequiredField';

function InputSelect(props) {

    let margin = {};
    if (props.noMargin) {
        margin = {marginBottom: 0};
    }

    return (
        <>
            <Form.Group style={margin}>
                {
                    !props.disableLabel &&
                    <Form.Label>{props.name}{props.required ? <RequiredField /> : ''}</Form.Label>
                }
                <Form.Control as="select" multiple={props.multiple} id={props.name} name={props.id} onChange={props.onChange} value={props.value} required={props.required} disabled={props.disabled}>
                    {
                        !props.multiple &&
                            <option key={''} value={''}>{'Selecione...'}</option>
                    }
                    {
                        props.options.sort((a, b) => ((a.value > b.value) && 1) || -1).map(opt => (
                            <option key={opt.key} value={opt.key}>{opt.value}</option>
                        ))
                    }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Campo {props.name} inválido
                </Form.Control.Feedback>
                {
                    props.additionalText &&
                    <Form.Text className="text-muted">
                        {props.additionalText}
                    </Form.Text>
                }
            </Form.Group>
        </>
    );
}

export default InputSelect;