import React, { useEffect, useState } from 'react';
import Template from '../../../components/organisms/Template';
import { Form, Row, Col } from 'react-bootstrap';
import { userChangePassword } from '../../../services/Users';
import InputText from '../../../components/atoms/InputText';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBar from '../../../components/molecules/ButtonBar';
import { getLoggedUser } from '../../../services/Users';
import { changedPassword } from '../../../helpers/authHelper';
import useForm from '../../../helpers/formHelper';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';

function ChangePassword(props) {

  const [loading, setLoading] = useState(true);
  const alert = useAlert();

  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const { inputs, handleInputChange, setInputs, validateInputs } = useForm();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getLoggedUser(alert);
      if (result) {
        result.password = '';
        setInputs(result);
      }
      setLoading(false);
    };

    fetchData();
  }, [setInputs]);

  const edit = async () => {
    setLoading(true);
    const response = await userChangePassword(inputs._id, inputs.senha, alert);

    if (response) {
      changedPassword();
      props.history.push(`/`);
      alert.success('Senha alterada com sucesso');
    }
    setLoading(false);
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const errors = validateInputs({ page: 'changePassword' });
      if (errors && errors.length > 0) {
        event.stopPropagation();
        setValidationErrors(errors);
      } else {
        edit();
      }
    }

    setValidated(true);
  };

  return (
    <Template disableRedirect={true} disableMenu={true} validationErrors={validationErrors} clearErrosFunc={() => setValidationErrors([])}
      loading={loading}>
      <PageHeader tittle="Troca de senha" />
      <Row>
        <Col>
          <h4>É necessário trocar sua senha no primeiro acesso!</h4>
        </Col>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <InputText id="login" name="Login" value={inputs.login || ''} onChange={handleInputChange} disabled />
          </Col>
          <Col>
            <InputText id="nome" name="Nome" value={inputs.nome || ''} onChange={handleInputChange} disabled />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="senha" type="password" name="Senha" onChange={handleInputChange} value={inputs.senha || ''} required />
          </Col>
          <Col>
            <InputText id="senhaConfirmacao" type="password" name="Confirmação" onChange={handleInputChange} value={inputs.senhaConfirmacao || ''} required />
          </Col>
        </Row>
        <ButtonBar>
          <ButtonSubmit variant="success" type="submit">Salvar</ButtonSubmit>
        </ButtonBar>
      </Form>
    </Template>
  );
}

export default ChangePassword;