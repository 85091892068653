import React from 'react';

function PageTittle(props) {
    if (props.min) {
        return (
            <>
                <h5>{props.children}</h5>
            </>
        );
    } else {
        return (
            <>
                <h3>{props.children}</h3>
            </>
        );
    }
}

export default PageTittle;