import React, { useState, useEffect } from 'react';
import Copyright from '../../molecules/Copyright';
import Loading from '../../atoms/Loading';
import Menu from '../../molecules/Menu';
import { Col, Container } from 'react-bootstrap';
import { getUserSession, isUnauthorized } from '../../../helpers/authHelper';
import { authorizedRoute } from '../../../helpers/routerPermissionHelper';
import VerticalSpace from '../../atoms/VerticalSpace';
import { withRouter } from 'react-router-dom';
import { useAlert } from 'react-alert';
import ModalValidationError from '../../../components/organisms/ModalValidationError';
import ModalTaskInconsistent from '../../../components/organisms/ModalTaskInconsistent';

function Template(props) {

  const alert = useAlert();
  const userSession = getUserSession();
  const [validationErrorsModalShow, setValidationErrorsModalShow] = useState(false);
  const [inconsistenciasModalShow, setInconsistenciasModalShow] = useState(false);

  const pathUrl = props.history.location.pathname;

  if (!userSession) {
    if (!props.disableRedirect) {
      alert.info('Sessão expirada! Por favor faça login novamente!');
      if (pathUrl) {
        props.history.push(`/login?redirect=${pathUrl}`);
      } else {
        props.history.push(`/login`);
      }
    }
  } else {
    if (userSession.trocarSenha && !props.disableRedirect) {
      props.history.push(`/changepassword`);
    }

    if (!authorizedRoute(pathUrl, userSession.perfil) || isUnauthorized()) {
      props.history.push(`/unauthorized`);
    }
  }

  useEffect(() => {
    if (props && props.validationErrors && props.validationErrors.length > 0) {
      setValidationErrorsModalShow(true);
    }
  }, [props, setValidationErrorsModalShow]);

  useEffect(() => {
    if (props && props.inconsistencias && props.inconsistencias.length > 0) {
      setInconsistenciasModalShow(true);
    }
  }, [props, setInconsistenciasModalShow]);

  if (props.onlyContent) {
    return (
      <>
        <Container fluid>
          <Loading loading={props.loading}>
            <Col>
              {props.children}
            </Col>
          </Loading>
        </Container>
      </>
    );
  } else {
    return (
      <>
        <div className="fundo">
          <Menu {...props} user={userSession} />
          <Container fluid>
            <Loading loading={props.loading}>
              <Col md={{ span: 10, offset: 1 }}>
                {props.children}
              </Col>
            </Loading>
          </Container>
          <VerticalSpace size={4} />
          <>
            <Copyright />
          </>
        </div>
        <ModalValidationError show={validationErrorsModalShow} errors={props.validationErrors}
          onHide={() => { setValidationErrorsModalShow(false); props.clearErrosFunc() }} />
        <ModalTaskInconsistent show={inconsistenciasModalShow} inconsistencias={props.inconsistencias}
          onHide={() => { setInconsistenciasModalShow(false); props.clearInconsFunc([]) }} />
      </>
    );
  }
}

export default withRouter(Template);