import React from 'react';
import Template from '../../../components/organisms/Template';
import { Jumbotron, Container } from 'react-bootstrap';

function NotFound() {
  return (
    <Template>
      <Container>
        <Jumbotron>
          <h1>Ops!</h1>
          <p>Ocorreu um erro, por favor repita a operação!</p>
        </Jumbotron>
      </Container>
    </Template>
  );
}

export default NotFound;