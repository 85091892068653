import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import InputText from '../../atoms/InputText';
import InputDate from '../../atoms/InputDate';
import useForm from '../../../helpers/formHelper';
import InputSelectTipoCompromisso from '../../molecules/InputSelectTipoCompromisso';
import InputSelectStatusCompromisso from '../../molecules/InputSelectStatusCompromisso';

function ModalSearchTasks(props) {

  const { inputs, handleInputChange, handleInputChangeMultiple, setInputs } = useForm();
  const [dataHoraInicio, setDataHoraInicio] = useState();
  const [dataHoraFim, setDataHoraFim] = useState();

  const search = async () => {
    props.searchFunction({
      nome: inputs.nome, tipo: inputs.tipo, status: inputs.status,
      dataHoraInicio: dataHoraInicio, dataHoraFim: dataHoraFim
    });
  }

  const clear = () => {
    setDataHoraInicio();
    setDataHoraFim();
    setInputs({});
  }

  return (
    <Modal className="fundo" show={props.show} onHide={() => { clear(); props.onHide(); }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="fundo" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Buscar Compromissos
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fundo">
        <Row>
          <Col>
            <InputText id="nome" name="Nome" onChange={handleInputChange} value={inputs.nome || ''} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputDate id="dataHoraInicio" name="Data De" onChange={date => setDataHoraInicio(date)} value={dataHoraInicio || ''} />
          </Col>
          <Col>
            <InputDate id="dataHoraFim" name="Data Até" onChange={date => setDataHoraFim(date)} value={dataHoraFim || ''} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputSelectTipoCompromisso id="tipo" name="Tipo Compromisso" multiple={true} onChange={handleInputChangeMultiple} value={inputs.tipo || ''} />
          </Col>
          <Col>
            <InputSelectStatusCompromisso id="status" name="Status Compromisso" multiple={true} onChange={handleInputChangeMultiple} value={inputs.status || ''} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="fundo">
        <Button variant="secondary" className="float-left" onClick={() => clear()}>Limpar</Button>
        <Button variant="success" className="float-right" onClick={() => search()}>Buscar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalSearchTasks;