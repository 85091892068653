import md5 from 'md5';
import { callGetMethod, callPostMethod, callPutMethod, callDeleteMethod } from '../../helpers/serviceHelper';
import * as SERVICES from '../../config/serviceConstants';
import { login, getUserSession } from "../../helpers/authHelper";

const senhaPadrao = "12345678";

export const getUser = async (id, alert) => {
    try {
        const user = await callGetMethod(SERVICES.USERS, id);
        return user;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getLoggedUser = async (alert) => {
    try {
        const userSession = getUserSession();
        const user = await callGetMethod(SERVICES.USERS, userSession.id);
        return user;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const loginUser = async (userLogin, senha, alert) => {
    try {
        const user = await callPostMethod(SERVICES.USER_LOGIN, { login: userLogin, senha: md5(senha) });
        login(user);
        return user;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const userChangePassword = async (id, senha, alert) => {
    try {
        const user = await callPostMethod(SERVICES.USER_EDIT_PASSOWRD, { _id: id, senha: md5(senha) });
        return user;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const resetUserPassword = async (id, alert) => {
    try {
        const response = await callGetMethod(SERVICES.USER_RESET_PASSWORD, id);
        return response;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getUsers = async (alert) => {
    try {
        const users = await callGetMethod(SERVICES.USERS);
        return users;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const editUser = async (id, body, alert, addValidationError) => {
    try {
        const user = await callPutMethod(SERVICES.USERS, id, body);
        return user;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const removeUser = async (id, alert) => {
    try {
        const response = await callDeleteMethod(SERVICES.USERS, id);
        return response;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const registerUser = async (body, alert, addValidationError) => {
    try {
        const user = await callPostMethod(SERVICES.USERS, {...body, senha: md5(senhaPadrao)});
        return user;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}