export const USERS = 'USERS';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_EDIT_PASSOWRD = 'USER_EDIT_PASSOWRD';

export const TASKS = 'TASKS';
export const TASKS_SEARCH = 'TASKS_SEARCH';
export const TASKS_HISTORY = 'TASKS_HISTORY';

export const DASHBOARD = 'DASHBOARD';
export const DASHBOARD_FIND_BYDATE = 'DASHBOARD_FIND_BYDATE';
export const DASHBOARD_FIND_BYPERIOD = 'DASHBOARD_FIND_BYPERIOD';
export const DASHBOARD_FIND_BYNAME = 'DASHBOARD_FIND_BYNAME';

export const REPORTS_TASK_INCONSISTENTS = 'REPORTS_TASK_INCONSISTENTS';
export const REPORTS_PAGAMENTOS_PENDENTES = 'REPORTS_PAGAMENTOS_PENDENTES';
export const REPORTS_PAGAMENTOS_FUTUROS = 'REPORTS_PAGAMENTOS_FUTUROS';
export const REPORTS_PAGAMENTOS_PASSADOS = 'REPORTS_PAGAMENTOS_PASSADOS';
export const REPORTS_TASKS_DELETED = 'REPORTS_TASKS_DELETED';
export const REPORTS_TASKS_CANCELED = 'REPORTS_TASKS_CANCELED';
export const REPORTS_TASKS_REALIZED = 'REPORTS_TASKS_REALIZED';
export const REPORTS_PAGAMENTOS_FUTUROS_MENSAL = 'REPORTS_PAGAMENTOS_FUTUROS_MENSAL';
export const REPORTS_PAGAMENTOS_PASSADOS_MENSAL = 'REPORTS_PAGAMENTOS_PASSADOS_MENSAL';
export const REPORTS_BALANCO_PAGAMENTOS = 'REPORTS_BALANCO_PAGAMENTOS';

export const REMINDERS = 'REMINDERS';
export const REMINDERS_SEARCH = 'REMINDERS_SEARCH';

export const COMPANIES = 'COMPANIES';

export const GET_DOC = 'GET_DOC';
export const SAVE_DOC = 'SAVE_DOC';
export const REMOVE_DOC = 'REMOVE_DOC';

export const ADMIN_USERS = 'ADMIN_USERS';
export const ADMIN_REPRESENT = 'ADMIN_REPRESENT';