import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

function Table(props) {

    const noDataIndication = () => {
        return (
            <>
                <h6>Sem dados para exibir!</h6>
            </>
        );
    };

    return (
        <>
            <BootstrapTable noDataIndication={noDataIndication} bootstrap4 bordered={false} hover {...props} />
        </>
    );
}

export default Table;