import React, { useState } from 'react';
import { Modal, Form, Button, ButtonGroup, Row, Col } from 'react-bootstrap';

function ModalSelectColumns(props) {
  const [copyColumnsProps, setCopyColumnsProps] = useState({ ...props.columnsProps });

  const handleInputChange = (event) => {
    event.persist();
    let elem = { ...copyColumnsProps[event.target.name] };
    elem.visibility = event.target.checked;
    setCopyColumnsProps({ ...copyColumnsProps, [event.target.name]: elem });
  }

  const selectAll = () => {
    let copyColumns = { ...copyColumnsProps };
    Object.keys(copyColumnsProps).forEach(column => {
      let elem = { ...copyColumnsProps[column] };
      elem.visibility = true;
      copyColumns = { ...copyColumns, [column]: elem };
    });
    setCopyColumnsProps(copyColumns);
  };

  const unselectAll = () => {
    let copyColumns = { ...copyColumnsProps };
    Object.keys(copyColumnsProps).forEach(column => {
      if (!copyColumnsProps[column].block) {
        let elem = { ...copyColumnsProps[column] };
        elem.visibility = false;
        copyColumns = { ...copyColumns, [column]: elem };
      }
    });
    setCopyColumnsProps(copyColumns);
  };

  const reset = () => {
    setCopyColumnsProps({ ...props.columnsProps });
  };

  const resetDefault = () => {
    setCopyColumnsProps({ ...props.initialColumnsProps });
  };

  const save = () => {
    props.setColumnsProps(copyColumnsProps);
    props.onHide();
  };

  return (
    <Modal className="fundo" show={props.show} onHide={save} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="fundo" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Selecionar Colunas
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fundo">
        <ButtonGroup>
          <Button size="sm" variant="secondary" style={{ marginRight: 2 }} onClick={() => selectAll()}>Marcar Todos</Button>
          <Button size="sm" variant="secondary" style={{ marginRight: 2 }} onClick={() => unselectAll()}>Desmarcar Todos</Button>
          <Button size="sm" variant="secondary" style={{ marginRight: 2 }} onClick={() => reset()}>Resetar</Button>
          <Button size="sm" variant="secondary" style={{ marginRight: 2 }} onClick={() => resetDefault()}>Resetar Padrão</Button>
        </ButtonGroup>
        <br />
        <br />
        <Row>
          <Col>
            {
              Object.keys(copyColumnsProps).map((column, index) => {
                if (index % 2 === 0) {
                  return (
                    <Form.Check type="checkbox" key={column} name={column} id={`checkbox-${column}`}
                      label={copyColumnsProps[column].name}
                      checked={copyColumnsProps[column].visibility}
                      onChange={handleInputChange} disabled={copyColumnsProps[column].block} />
                  )
                } else {
                  return (<></>)
                }
              })
            }
          </Col>
          <Col>
            {
              Object.keys(copyColumnsProps).map((column, index) => {
                if (index % 2 === 1) {
                  return (
                    <Form.Check type="checkbox" key={column} name={column} id={`checkbox-${column}`}
                      label={copyColumnsProps[column].name}
                      checked={copyColumnsProps[column].visibility}
                      onChange={handleInputChange} disabled={copyColumnsProps[column].block} />
                  )
                } else {
                  return (<></>)
                }
              })
            }
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="fundo">
        <Button variant="success" className="float-right" onClick={save}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalSelectColumns;