import React from 'react';
import { Card } from 'react-bootstrap';

function CardDashboard(props) {
    return (
        <>
            <Card bg="" className="fundo" style={{ minHeight: '85px', marginTop: '8px' }}>
                <Card.Header as="h6">{props.header}</Card.Header>
                <Card.Body className="itens-centered" style={{ paddingTop: '10px', paddingBottom: '0px'}}>
                    {props.children}
                </Card.Body>
            </Card>
        </>
    );
}

export default CardDashboard;