import React from 'react';
import { Form } from 'react-bootstrap';
import RequiredField from '../RequiredField';

function InputText(props) {
    if (props.hidden) {
        return (<></>);
    } else {
        return (
            <>
                <Form.Group>
                    <Form.Label>{props.name}{props.required ? <RequiredField /> : ''}</Form.Label>
                    <Form.Control type={props.type} name={props.id} placeholder={props.disabled ? '' : `Inserir ${props.name}`}
                        disabled={props.disabled} onChange={props.onChange} value={props.value} required={props.required}
                        min={props.min} max={props.max} step={props.step} />
                    <Form.Control.Feedback type="invalid">
                        Campo {props.name} inválido
                    </Form.Control.Feedback>
                    {
                        props.additionalText &&
                        <Form.Text className="text-muted">
                            {props.additionalText}
                        </Form.Text>
                    }

                </Form.Group>
            </>
        );
    }
}

InputText.defaultProps = {
    type: 'text'
}

export default InputText;