import { callPostMethod } from '../../helpers/serviceHelper';
import * as SERVICES from '../../config/serviceConstants';

export const getDoc = async (body, alert) => {
    try {
        const task = await callPostMethod(SERVICES.GET_DOC, body);
        return task;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const removeDoc = async (body, alert) => {
    try {
        const response = await callPostMethod(SERVICES.REMOVE_DOC, body);
        return response;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const saveDoc = async (body, alert, addValidationError) => {
    try {
        const task = await callPostMethod(SERVICES.SAVE_DOC, body);
        return task;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}