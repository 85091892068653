import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Button, Container, ButtonGroup } from 'react-bootstrap';
import { getUsers } from '../../../services/Admin';
import { FaPlusCircle, FaSync, FaColumns } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import Table from '../../../components/atoms/Table';
import { formatDateTime } from '../../../helpers/functions';
import { optionsFormatter, rowFormatter } from '../../../helpers/tableFunctions';
import PageHeader from '../../../components/molecules/PageHeader';
import ModalSelectColumns from '../../../components/organisms/ModalSelectColumns';

function List() {

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getUsers(alert);
      if (result) {
        setUsers(result);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const refresh = async () => {
    setLoading(true);
    const result = await getUsers(alert);
    if (result) {
      setUsers(result);
      alert.success('Lista atualizada');
    }
    setLoading(false);
  };

  const initialColumnsProps = {
    login: { name: 'Login', visibility: true },
    nome: { name: 'Nome', visibility: true },
    sobrenome: { name: 'Sobrenome', visibility: true },
    empresa: { name: 'Empresa', visibility: true },
    visivel: { name: 'Visível', visibility: true },
    perfil: { name: 'Perfil', visibility: false },
    responsavel: { name: 'Responsável', visibility: false },
    dataHoraCriacao: { name: 'Criação', visibility: false },
  };

  const [columnsProps, setColumnsProps] = useState(initialColumnsProps);
  const [selectColumnsModalShow, setSelectColumnsModalShow] = useState(false);

  const usersColumn = [
    {
      dataField: '_id', text: '#',
      formatter: (cell, row, rowIndex) => (<>{rowFormatter(rowIndex, users, row)}</>)
    },
    {
      dataField: 'login', text: columnsProps['login'].name, sort: true,
      hidden: !columnsProps['login'].visibility,
    },
    {
      dataField: 'nome', text: columnsProps['nome'].name, sort: true,
      hidden: !columnsProps['nome'].visibility,
    },
    {
      dataField: 'sobrenome', text: columnsProps['sobrenome'].name, sort: true,
      hidden: !columnsProps['sobrenome'].visibility,
    },
    {
      dataField: 'empresa.nome', text: columnsProps['empresa'].name, sort: true,
      hidden: !columnsProps['empresa'].visibility,
    },
    {
      dataField: 'visivel', text: columnsProps['visivel'].name, sort: true,
      hidden: !columnsProps['visivel'].visibility,
    },
    {
      dataField: 'perfil', text: columnsProps['perfil'].name, sort: true,
      hidden: !columnsProps['perfil'].visibility,
    },
    {
      dataField: 'responsavel.nome', text: columnsProps['responsavel'].name, sort: true,
      hidden: !columnsProps['responsavel'].visibility,
    },
    {
      dataField: 'dataHoraCriacao', text: columnsProps['dataHoraCriacao'].name, sort: true,
      hidden: !columnsProps['dataHoraCriacao'].visibility,
      formatter: (cell) => (<>{formatDateTime(cell)}</>),
    },
    {
      dataField: '_id', text: 'Opções',
      formatter: (cell) => (<>{optionsFormatter(cell, 'admin/user')}</>),
    }
  ];

  return (
    <Template loading={loading}>
      <PageHeader tittle="ADMIN - Usuários">
        <ButtonGroup className="float-right">
          <Button variant="success" href="/admin/user/new">
            <FaPlusCircle />
          </Button>
          <Button variant="primary" onClick={() => refresh()}>
            <FaSync />
          </Button>
          <Button variant="secondary" onClick={() => setSelectColumnsModalShow(true)}>
            <FaColumns />
          </Button>
        </ButtonGroup>
      </PageHeader>
      <Row>
        <Container className="container container-nobars">
          <Table data={users} columns={usersColumn} keyField='_id'
            defaultSorted={[{ dataField: 'login', order: 'asc' }]} />
        </Container>
      </Row>
      <ModalSelectColumns show={selectColumnsModalShow} columnsProps={columnsProps}
        setColumnsProps={setColumnsProps} initialColumnsProps={initialColumnsProps}
        onHide={() => setSelectColumnsModalShow(false)} />
    </Template>
  );
}

export default List;