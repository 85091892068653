import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('ptBR', { ...ptBR, options: { ...ptBR.options, weekStartsOn: 1 } });

function InputDateSearch(props) {

    const datasOcupadas = props.datasOcupadas?.map(data => (new Date(data))) || [];
    const datasLembretes = props.datasLembretes?.map(data => (new Date(data))) || [];

    function mountBoth(list1, list2) {
        var result = [];
        if (list1 && list2) {
            for (var i = 0; i < list1.length; i++) {
                for (var j = 0; j < list2.length; j++) {
                    if (list1[i].setHours(0,0,0,0) === list2[j].setHours(0,0,0,0)) {
                        result.push(list1[i]);
                    }
                }

            }
        }
        return result;
    }

    const highlightDates = [
        {
            "react-datepicker__day--highlighted-custom-3":
                mountBoth(datasOcupadas, datasLembretes) || []
        },
        {
            "react-datepicker__day--highlighted-custom-1":
                datasOcupadas || []
        },
        {
            "react-datepicker__day--highlighted-custom-2":
                datasLembretes || []
        }
    ];

    return (
        <>
            <DatePicker name={props.id} autoComplete="off"
                onChange={props.onChange} locale="ptBR"
                showMonthDropdown showYearDropdown fixedHeight
                onCalendarOpen={() => { document.activeElement.blur() }}
                showTimeSelect={false} dateFormat="dd/MM/yy" inline
                highlightDates={highlightDates}
                openToDate={props.value}
            />
        </>
    );
}

export default InputDateSearch;