import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import RequiredField from '../RequiredField';

function InputMoney(props) {
    return (
        <>
            <Form.Group>
                <Form.Label>{props.name}{props.required ? <RequiredField /> : ''}</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>R$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control inputMode="numeric" min="0" max="99999" step="1" type="number" name={props.id}
                        placeholder={props.disabled ? '' : `Inserir ${props.name}`}
                        disabled={props.disabled} onChange={props.onChange} value={props.value}
                        required={props.required} />
                    <Form.Control.Feedback type="invalid">
                        Campo {props.name} inválido
                    </Form.Control.Feedback>
                </InputGroup>
                {
                    props.additionalText &&
                    <Form.Text className="text-muted">
                        {props.additionalText}
                    </Form.Text>
                }

            </Form.Group>
        </>
    );
}

export default InputMoney;