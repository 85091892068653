import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import InputMoney from '../../atoms/InputMoney';
import InputDate from '../../atoms/InputDate';
import useForm from '../../../helpers/formHelper';
import InputSelectTipoPagamento from '../../molecules/InputSelectTipoPagamento';
import InputSelectFormaPagamento from '../../molecules/InputSelectFormaPagamento'

function ModalInsertPayment(props) {

  const { inputs, handleInputChange, setInputs } = useForm();
  const [dataHora, setDataHora] = useState();
  const [validated, setValidated] = useState(false);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      props.saveFunction({ data: dataHora, tipo: inputs.tipo, formaPagamento: inputs.forma, valor: inputs.valor });
    }

    setValidated(true);
  };

  const clear = () => {
    setDataHora();
    setInputs({});
    setValidated(false);
  };

  useEffect(() => {
    clear();
  }, [props]);

  return (
    <Modal className="fundo" show={props.show} onHide={() => props.onHide()} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header className="fundo" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Inserir Pagamento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="fundo">
          <Row>
            <Col>
              <InputSelectTipoPagamento id="tipo" name="Status do Pagamento" 
              onChange={handleInputChange} value={inputs.tipo || ''} required />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputSelectFormaPagamento id="forma" name="Forma de Pagamento"
                onChange={handleInputChange} value={inputs.forma || ''} required />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputMoney id="valor" name="Valor" onChange={handleInputChange} value={inputs.valor || ''} required />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputDate id="dataHora" name="Data" onChange={date => setDataHora(date)} value={dataHora || ''} required />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="fundo">
          <Button variant="secondary" className="float-left" onClick={() => clear()}>Limpar</Button>
          <Button variant="success" className="float-right" type="submit">Inserir</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ModalInsertPayment;