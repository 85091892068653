import Axios from 'axios';
import { mapEndpoints } from '../config/endpoints';
import { getUserSession, logout, setUnauthorized } from "./authHelper";

const api = Axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`
});

api.interceptors.request.use(async config => {
    const user = getUserSession();
    if (user) {
        config.headers.Token = user.token;
    }

    return config;
});

api.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        logout();
        return undefined;
    }

    if (error.response.status === 403) {
        setUnauthorized();
        return undefined;
    }

    return error.response;
});

export const callGetMethod = (service, params, header) => {
    const headers = header || {};
    const endpoint = mapEndpoints.get(service);

    return api.get(`${endpoint}/${params || ''}`, { headers })
        .then((response) => {
            if (response) {
                if (response.status === 200) {
                    return response.data;
                } else if (response.status < 299 || response.status === 403) {
                    return response;
                } else {
                    throw response;
                }
            }
        }).catch(error => {
            throw error;
        });
}

export const downloadFile = (service, params, fileName) => {
    const endpoint = mapEndpoints.get(service);
    const method = 'GET';
    const url = `${endpoint}/${params || ''}`;
    api.request({
        url,
        method,
        responseType: 'blob',
    }).then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
}

export const callPostMethod = (service, body, header) => {
    const headers = header || {};
    const endpoint = mapEndpoints.get(service);

    return api.post(`${endpoint}/`, body, { headers })
        .then((response) => {
            if (response) {
                if (response.status === 200) {
                    return response.data;
                } else if (response.status < 299 || response.status === 403) {
                    return response;
                } else {
                    throw response;
                }
            }
        }).catch(error => {
            throw error;
        });
}

export const callPutMethod = (service, params, body, header) => {
    const headers = header || {};
    const endpoint = mapEndpoints.get(service);

    return api.put(`${endpoint}/${params || ''}`, body, { headers })
        .then((response) => {
            if (response) {
                if (response.status === 200) {
                    return response.data;
                } else if (response.status < 299 || response.status === 403) {
                    return response;
                } else {
                    throw response;
                }
            }
        }).catch(error => {
            throw error;
        });
}

export const callDeleteMethod = (service, params, header) => {
    const headers = header || {};
    const endpoint = mapEndpoints.get(service);

    return api.delete(`${endpoint}/${params || ''}`, { headers })
        .then((response) => {
            if (response) {
                if (response.status === 200) {
                    return response.data;
                } else if (response.status < 299 || response.status === 403) {
                    return response;
                } else {
                    throw response;
                }
            }
        }).catch(error => {
            throw error;
        });
}