import React, { useState } from 'react';
import { loginUser } from '../../../services/Users';
import Template from '../../../components/organisms/Template';
import { Row, Col, Form } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import useForm from '../../../helpers/formHelper';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import PageTittle from '../../../components/atoms/PageTittle';
import VerticalSpace from '../../../components/atoms/VerticalSpace';
import { useAlert } from "react-alert";
import Logo from '../../../components/atoms/Logo';
import { getRedirectUrlLocation } from '../../../helpers/functions';

function Login(props) {

  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const login = async () => {
    setLoading(true);
    const response = await loginUser(inputs.login, inputs.senha, alert);
    if (response) {
      const redirectUrl = getRedirectUrlLocation(props.history.location);
      if (redirectUrl) {
        props.history.push(redirectUrl);
      } else {
        props.history.push('/');
      }

    }
    setLoading(false);
  }

  const { inputs, handleInputChange } = useForm();

  const [validated, setValidated] = useState(false);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      login();
    }

    setValidated(true);
  };

  return (
    <Template disableRedirect={true} disableMenu={true} disableUser={true} loading={loading}>
      <VerticalSpace />
      <Row>
        <Col>
          <Logo />
        </Col>
        <Col>
          <PageTittle>Login</PageTittle>
          <VerticalSpace />
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col>
                <InputText id="login" name="Login" onChange={handleInputChange} value={inputs.login || ''} required />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputText id="senha" type="password" name="Senha" onChange={handleInputChange} value={inputs.senha || ''} required />
              </Col>
            </Row>
            <Row>
              <Col>
                <ButtonSubmit className="float-right" variant="success" type="submit">Entrar</ButtonSubmit>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Template>
  );
}

export default Login;