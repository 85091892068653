import React from 'react';
import InputSelect from '../../atoms/InputSelect';
import InputSelectTable from '../../atoms/InputSelectTable';
import { getConfigCompany } from '../../../helpers/authHelper';
import { convertConfigToItensSelect } from '../../../helpers/functions';

const tiposPadrao = [
    { key: 'Dinheiro', value: 'Dinheiro' },
    { key: 'Cartão', value: 'Cartão' },
    { key: 'Transferência', value: 'Transferência' },
];

function InputSelectFormaPagamento(props) {
    const config = getConfigCompany("FORMAS_PAGAMENTO");
    const tipos = convertConfigToItensSelect(config);
    return (
        <>
            {props.editable &&
                <InputSelectTable {...props} options={tipos ? tipos : tiposPadrao} />
            }
            {!props.editable &&
                <InputSelect {...props} options={tipos ? tipos : tiposPadrao} />
            }
        </>
    );
}

export default InputSelectFormaPagamento;