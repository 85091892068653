import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';

function TableConfigurations(props) {

    const noDataIndication = () => {
        return (
            <>
                <h6>Sem dados para exibir!</h6>
            </>
        );
    };

    return (
        <>
            <BootstrapTable noDataIndication={noDataIndication} bootstrap4 bordered={true} 
            cellEdit={ props.editable ? cellEditFactory({ mode: 'click', blurToSave: true }) : undefined } hover {...props} />
        </>
    );
}

export default TableConfigurations;