import React from 'react';

function RequiredField() {
    return (
        <>
            <span style={{color: 'red'}}> *</span>
        </>
    );
}

export default RequiredField;