import React from 'react';
import { Button, Badge, ButtonGroup } from 'react-bootstrap';
import { FaEye, FaEdit, FaTrash, FaUserTie, FaDownload } from 'react-icons/fa';

export const optionsFormatter = (cell, model) => {
    return (
        <>
            <ButtonGroup>
                <Button variant="info" href={`/${model}/detail/${cell}`}><FaEye /></Button>
                <Button variant="warning" href={`/${model}/edit/${cell}`}><FaEdit /></Button>
                <Button variant="danger" href={`/${model}/detail/${cell}?remove=1`}><FaTrash /></Button>
            </ButtonGroup>
        </>
    )
};

export const optionsFormatterDetail = (cell, model) => {
    return (
        <>
            <ButtonGroup>
                <Button variant="info" href={`/${model}/detail/${cell}`}><FaEye /></Button>
            </ButtonGroup>
        </>
    )
};

export const optionsFormatterRepresent = (cell, model) => {
    return (
        <>
            <ButtonGroup>
                <Button variant="secondary" href={`/admin/representcompany/${cell}`}><FaUserTie /></Button>
                <Button variant="info" href={`/${model}/detail/${cell}`}><FaEye /></Button>
                <Button variant="warning" href={`/${model}/edit/${cell}`}><FaEdit /></Button>
                <Button variant="danger" href={`/${model}/detail/${cell}?remove=1`}><FaTrash /></Button>
            </ButtonGroup>
        </>
    )
};

export const optionsFormatterRemoveFunction = (func) => {
    return (
        <>
            <ButtonGroup>
            <Button variant="danger" onClick={() => func()}><FaTrash /></Button>
            </ButtonGroup>
        </>
    )
};

export const optionsFormatterDownloadFunction = (func) => {
    return (
        <>
            <ButtonGroup>
            <Button variant="primary" onClick={() => func()}><FaDownload /></Button>
            </ButtonGroup>
        </>
    )
};

export const rowFormatter = (rowIndex, array, row) => {
    if (array) {
        return (
            <>
                <h5 style={{ marginBottom: '0px' }}>
                    <Badge pill variant="secondary">
                        {(rowIndex + 1) + '/' + array.length}
                    </Badge>

                </h5>
                {(row && ((row.inconsistenciasCompromisso && row.inconsistenciasCompromisso.length > 0)
                    || (row.inconsistenciasPagamento && row.inconsistenciasPagamento.length > 0))) &&
                    <h6 style={{ marginBottom: '-18px' }}>
                        <Badge pill variant="danger"
                            style={{ position: 'relative', top: '-34px', left: '33px' }}>!</Badge>
                    </h6>
                }
            </>
        )
    } else {
        return <></>
    }
};