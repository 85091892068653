import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import ModalConfirmExit from '../../organisms/ModalConfirmExit';

function ButtonBackEdit(props) {

    const { staticContext, ...rest } = props;
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);

    const backPage = () => {
        props.history.go(-1);
    }

    const goBack = () => {
        if (props.notSaved) {
            setConfirmationModalShow(true);
        } else {
            backPage();
        }
    };

    return (
        <>
            <Button {...rest} variant="secondary" size="lg" className="float-left" onClick={goBack}>Voltar</Button>
            <ModalConfirmExit show={confirmationModalShow} onHide={() => setConfirmationModalShow(false)}
                onConfirm={() => { backPage() }} />
        </>
    );
}

export default withRouter(ButtonBackEdit);