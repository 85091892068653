import React from 'react';
import { Spinner, Container } from 'react-bootstrap';

function Loading(props) {
    if (props.loading) {
        return (
            <Container className="center-page">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Carregando...</span>
                </Spinner>
            </Container>
        )
    } else {
        return (
            <>
                {props.children}
            </>
        )
    }

}

export default Loading;