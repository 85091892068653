import React from 'react';
import { Row, Col } from 'react-bootstrap';
import VerticalSpace from '../../atoms/VerticalSpace';
import PageTittle from '../../atoms/PageTittle';

function PageHeader(props) {
  return (
    <>
      <Row>
        <Col>
          <PageTittle min={props.min}>{props.tittle}</PageTittle>
        </Col>
        <Col>
          {props.children}
        </Col>
      </Row>
      <VerticalSpace />
    </>
  );
}

export default PageHeader;