import React from 'react';
import Chart from "react-apexcharts";

function PieChart(props) {

  const options = {
    legend: {
      show: false
    },
    labels: props.labels,
    colors: ['#53A551', '#CB444A'],
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -15
        }
      }
    },
    dataLabels: {
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex]
        return [name, val.toFixed(1) + '%']
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300
        }
      }
    }]
  };

  return (
    <>
      <div className="mixed-chart">
        <Chart
          options={options}
          series={props.values || []}
          type="pie"
          width="400"
        />
      </div>
    </>
  );
}

export default PieChart;