import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col, Tab } from 'react-bootstrap';
import { getTaskHistory } from '../../../services/Tasks';
import { getIdFromUrlLocationPathname } from '../../../helpers/functions';
import VerticalSpace from '../../../components/atoms/VerticalSpace';
import ButtonBack from '../../../components/atoms/ButtonBack';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import ListHistory from '../../../components/molecules/ListHistory';
import ContentListHistory from '../../../components/molecules/ContentListHistory';

function History(props) {

  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);
  const id = getIdFromUrlLocationPathname(props.history.location.pathname);
  const alert = useAlert();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getTaskHistory(id, alert);
      if (result) {
        setHistory(result);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  return (
    <Template loading={loading}>
      <PageHeader tittle="Histórico do compromisso" />
      <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
        <Row>
          <Col sm={2.1}>
            <ListHistory rows={history} />
          </Col>
          <Col sm={9}>
            <VerticalSpace />
            <ContentListHistory rows={history} />
          </Col>
        </Row>
      </Tab.Container>
      <VerticalSpace />
      <Row>
        <Col>
          <ButtonBack />
        </Col>
      </Row>
    </Template>
  );
}

export default History;