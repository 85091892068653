import React, { useState } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col, Form } from 'react-bootstrap';
import { registerUser } from '../../../services/Users';
import InputText from '../../../components/atoms/InputText';
import useForm from '../../../helpers/formHelper';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBar from '../../../components/molecules/ButtonBar';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';

function New(props) {

  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const addValidationError = (validationError) => {
    let validationsError = validationErrors.slice();
    validationsError.push(validationError);
    setValidationErrors(validationsError);
  }

  const register = async () => {
    setLoading(true);

    let user = {
      login: inputs.login,
      nome: inputs.nome,
      sobrenome: inputs.sobrenome,
    };

    const response = await registerUser(user, alert, addValidationError);

    if (response) {
      if (response._id) {
        props.history.push(`/user/detail/${response._id}`);
      } else {
        props.history.push(`/users`);
      }
      alert.success('Usuário criado com sucesso');
    }
    setLoading(false);
  }

  const { inputs, handleInputChange, validateInputs } = useForm();

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const errors = validateInputs({ page: 'createser' });
      if (errors && errors.length > 0) {
        event.stopPropagation();
        setValidationErrors(errors);
      } else {
        register();
      }
    }

    setValidated(true);
  };

  return (
    <Template validationErrors={validationErrors} clearErrosFunc={() => setValidationErrors([])}
      loading={loading}>
      <PageHeader tittle="Criar Usuário" />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <InputText id="login" name="Login" value={inputs.login || ''}
              onChange={handleInputChange} />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="nome" name="Nome" value={inputs.nome || ''}
              onChange={handleInputChange} />
          </Col>
          <Col>
            <InputText id="sobrenome" name="Sobrenome" value={inputs.sobrenome || ''}
              onChange={handleInputChange} />
          </Col>
        </Row>
        <ButtonBar>
          <ButtonSubmit variant="success" type="submit">Criar</ButtonSubmit>
        </ButtonBar>
      </Form>
    </Template>
  );
}

export default New;