import React from 'react';
import { Modal, Row, Container } from 'react-bootstrap';
import ExportTasks from '../../molecules/ExportTasks';

function ModalExportTasks(props) {
  return (
    <Modal className="fundo" show={props.show} onHide={props.onHide} size="xl"
      aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="fundo" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Lista de Compromissos
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fundo">
        <Row>
          <Container className="container container-nobars">
            <ExportTasks tasks={props.itens} />
          </Container>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ModalExportTasks;