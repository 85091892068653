import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PageHeader from '../../../components/molecules/PageHeader';
import Template from '../../../components/organisms/Template';
import { convertConfigToBoolean } from '../../../helpers/functions';
import { getConfigCompany } from '../../../helpers/authHelper';
import PagamentosPassados from '../PagamentosPassados';
import PagamentosPassadosMensal from '../PagamentosPassadosMensal';
import PagamentosFuturos from '../PagamentosFuturos';
import PagamentosFuturosMensal from '../PagamentosFuturosMensal';
import BalancoPagamentos from '../BalancoPagamentos';
import TasksDeleted from '../TasksDeleted';
import VerticalSpace from '../../../components/atoms/VerticalSpace';
import PagamentosPendentes from '../PagamentosPendentes';

function Reports() {

  const pagamentoHabilitado = convertConfigToBoolean(getConfigCompany("PAGAMENTO_HABILITADO"));

  return (
    <Template>
      <PageHeader tittle="Relatórios" />
      {pagamentoHabilitado &&
        <Tabs defaultActiveKey="bal-recebidos">
          <Tab eventKey="bal-recebidos" title="Balanço Mensal">
            <VerticalSpace />
            <BalancoPagamentos />
          </Tab>
          <Tab eventKey="pag-recebidos-mensal" title="Pag. Recebidos">
            <VerticalSpace />
            <PagamentosPassadosMensal />
          </Tab>
          <Tab eventKey="pag-futuros-mensal" title="Pag. Futuros">
            <VerticalSpace />
            <PagamentosFuturosMensal />
          </Tab>
          <Tab eventKey="pag-recebidos" title="Lista Pag. Recebidos">
            <VerticalSpace />
            <PagamentosPassados />
          </Tab>
          <Tab eventKey="pag-futuros" title="Lista Pag. Futuros">
            <VerticalSpace />
            <PagamentosFuturos />
          </Tab>
          <Tab eventKey="pag-pendentes" title="Lista Pag. Pendentes">
            <VerticalSpace />
            <PagamentosPendentes />
          </Tab>
          <Tab eventKey="comp-removidos" title="Comp. removidos">
            <VerticalSpace />
            <TasksDeleted />
          </Tab>
        </Tabs>
      }
      {!pagamentoHabilitado &&
        <Tabs defaultActiveKey="bal-recebidos">
          <Tab eventKey="comp-removidos" title="Comp. removidos">
            <VerticalSpace />
            <TasksDeleted />
          </Tab>
        </Tabs>
      }
    </Template>
  );
}

export default Reports;