import React from 'react';
import { Modal, Row, Container, Button } from 'react-bootstrap';

function ModalConfirmRemove(props) {
  return (
    <Modal show={props.show}
      aria-labelledby="contained-modal-title-vcenter" onHide={() => { props.onHide(); }} centered>
      <Modal.Header className="fundo" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Confirmação
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fundo">
        <Row>
          <Container className="container container-nobars">
            <p style={{ marginLeft: '2%' }}>
              <b>Deseja realmente remover esse item?</b>
            </p>
          </Container>
        </Row>
      </Modal.Body>
      <Modal.Footer className="fundo">
        <Button variant="secondary" onClick={() => { props.onHide(); }}>Não</Button>
        <Button variant="primary" onClick={() => { props.onConfirm(); }}>Sim</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConfirmRemove;