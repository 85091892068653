import React from 'react';
import { Modal, Row, Col, Container } from 'react-bootstrap';

function ModalValidationError(props) {

  function renderError(error, i) {
    return (
      <>
        <Row>
          <Col>
            <h6>{error}</h6>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal show={props.show} onHide={props.onHide} size="xl"
      aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="fundo" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Falha de validação
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fundo">
        <Row>
          <Container className="container container-nobars">
            <p style={{marginLeft: '2%'}}>
              <b>Não é possível salvar, verifique as falhas abaixo:</b>
              <br/><br/>
              {props.errors?.map(renderError)}
            </p>
          </Container>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ModalValidationError;