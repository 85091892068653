import * as CONSTANTS from "./serviceConstants";

export const mapEndpoints = new Map();
mapEndpoints.set(CONSTANTS.USERS, `usuarios`);
mapEndpoints.set(CONSTANTS.USER_LOGIN, `login`);
mapEndpoints.set(CONSTANTS.USER_RESET_PASSWORD, `resetsenha`);
mapEndpoints.set(CONSTANTS.USER_EDIT_PASSOWRD, `editpassword`);

mapEndpoints.set(CONSTANTS.TASKS, `tarefas`);
mapEndpoints.set(CONSTANTS.TASKS_SEARCH, `buscartarefas`);
mapEndpoints.set(CONSTANTS.TASKS_HISTORY, `history`);

mapEndpoints.set(CONSTANTS.DASHBOARD, `dashboard`);
mapEndpoints.set(CONSTANTS.DASHBOARD_FIND_BYDATE, `dashboard/findbydate`);
mapEndpoints.set(CONSTANTS.DASHBOARD_FIND_BYPERIOD, `dashboard/findbyperiod`);
mapEndpoints.set(CONSTANTS.DASHBOARD_FIND_BYNAME, `dashboard/findbyname`);

mapEndpoints.set(CONSTANTS.REPORTS_TASK_INCONSISTENTS, `reports/tasksinconsistents`);
mapEndpoints.set(CONSTANTS.REPORTS_PAGAMENTOS_PENDENTES, `reports/pagamentospendentes`);
mapEndpoints.set(CONSTANTS.REPORTS_PAGAMENTOS_FUTUROS, `reports/pagamentosfuturos`);
mapEndpoints.set(CONSTANTS.REPORTS_PAGAMENTOS_PASSADOS, `reports/pagamentospassados`);
mapEndpoints.set(CONSTANTS.REPORTS_PAGAMENTOS_FUTUROS_MENSAL, `reports/pagamentosfuturosmensal`);
mapEndpoints.set(CONSTANTS.REPORTS_PAGAMENTOS_PASSADOS_MENSAL, `reports/pagamentospassadosmensal`);
mapEndpoints.set(CONSTANTS.REPORTS_TASKS_DELETED, `reports/tasksdeleted`);
mapEndpoints.set(CONSTANTS.REPORTS_TASKS_CANCELED, `reports/taskscanceled`);
mapEndpoints.set(CONSTANTS.REPORTS_TASKS_REALIZED, `reports/tasksrealized`);
mapEndpoints.set(CONSTANTS.REPORTS_BALANCO_PAGAMENTOS, `reports/balancopagamentos`);

mapEndpoints.set(CONSTANTS.REMINDERS, `lembretes`);
mapEndpoints.set(CONSTANTS.REMINDERS_SEARCH, `searchlembretes`);

mapEndpoints.set(CONSTANTS.COMPANIES, `empresas`);

mapEndpoints.set(CONSTANTS.GET_DOC, `getdoc`);
mapEndpoints.set(CONSTANTS.SAVE_DOC, `savedoc`);
mapEndpoints.set(CONSTANTS.REMOVE_DOC, `removedoc`);

mapEndpoints.set(CONSTANTS.ADMIN_USERS, `admin/usuarios`);
mapEndpoints.set(CONSTANTS.ADMIN_REPRESENT, `admin/repempresa`);