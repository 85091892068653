import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import { getCompany, removeCompany } from '../../../services/Companies';
import { getIdFromUrlLocationPathname, isRemoveUrlLocation, formatDateTime } from '../../../helpers/functions';
import VerticalSpace from '../../../components/atoms/VerticalSpace';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBack from '../../../components/atoms/ButtonBack';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import Session from '../../../components/atoms/Session';
import TableConfigurations from '../../../components/atoms/TableConfigurations';
import ModalConfirmRemove from '../../../components/organisms/ModalConfirmRemove';

function Detail(props) {

  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({});
  const id = getIdFromUrlLocationPathname(props.history.location.pathname);
  const isRemove = isRemoveUrlLocation(props.history.location);
  const alert = useAlert();

  const [confirmRemoveModalShow, setConfirmRemoveModalShow] = useState(isRemove);

  const remove = async () => {
    setLoading(true);
    const response = await removeCompany(id, alert);
    if (response) {
      props.history.push('/companies');
      alert.success('Empresa removida com sucesso');
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getCompany(id, alert);
      if (result) {
        setCompany(result);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  return (
    <Template loading={loading}>
      <PageHeader tittle="Detalhes da empresa" />
      <Row>
        <Col>
          <InputText name="Nome"
            value={company.nome || ''} disabled />
        </Col>
      </Row>
      <Session name="Configurações">
        <TableConfigurations columns={[{ dataField: 'chave', text: 'Chave' }, { dataField: 'valor', text: 'Valor' }]}
          keyField='chave' data={company.configuracoes || []} />
      </Session>
      <Session name="Informações">
        <Row>
          <Col>
            <InputText name="Criado Por" value={company?.responsavel?.nome || ''} disabled />
          </Col>
          <Col>
            <InputText name="Data Criação"
              value={company.dataHoraCriacao ? formatDateTime(company.dataHoraCriacao) : ''} disabled />
          </Col>
        </Row>
      </Session>
      <VerticalSpace />
      <Row>
        <Col style={{ paddingRight: '5px' }}>
          <ButtonBack />
        </Col>
        <Col className="col-md-1" style={{ paddingRight: '5px' }}>
          <ButtonSubmit className="center-block" variant="warning"
            onClick={() => { props.history.push(`/company/edit/${id}`) }}>Editar</ButtonSubmit>
        </Col>
        <Col style={{ paddingRight: '5px' }}>
          <ButtonSubmit className="float-right" variant="danger"
            onClick={() => { setConfirmRemoveModalShow(true) }}>Remover</ButtonSubmit>
        </Col>
      </Row>
      <ModalConfirmRemove show={confirmRemoveModalShow} onHide={() => setConfirmRemoveModalShow(false)}
        onConfirm={() => { remove() }} />
    </Template>
  );
}

export default Detail;