import { callGetMethod, callPostMethod } from '../../helpers/serviceHelper';
import * as SERVICES from '../../config/serviceConstants';

export const getTasksPagamentosFuturos = async (filterBody, alert) => {
    try {
        const tasks = await callPostMethod(SERVICES.REPORTS_PAGAMENTOS_FUTUROS, filterBody);
        return tasks;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getTasksPagamentosPassados = async (filterBody, alert) => {
    try {
        const tasks = await callPostMethod(SERVICES.REPORTS_PAGAMENTOS_PASSADOS, filterBody);
        return tasks;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getTasksPagamentosPendentes = async (filterBody, alert) => {
    try {
        const tasks = await callPostMethod(SERVICES.REPORTS_PAGAMENTOS_PENDENTES, filterBody);
        return tasks;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getTasksPagamentosFuturosMensal = async (alert) => {
    try {
        const tasks = await callGetMethod(SERVICES.REPORTS_PAGAMENTOS_FUTUROS_MENSAL);
        return tasks;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getTasksPagamentosPassadosMensal = async (alert) => {
    try {
        const tasks = await callGetMethod(SERVICES.REPORTS_PAGAMENTOS_PASSADOS_MENSAL);
        return tasks;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getBalancoPagamentos = async (alert) => {
    try {
        const tasks = await callGetMethod(SERVICES.REPORTS_BALANCO_PAGAMENTOS);
        return tasks;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}