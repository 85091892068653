import React from 'react';
import Session from '../../../components/atoms/Session';
import Table from '../../../components/atoms/Table';
import { formatDate, formatMoney } from '../../../helpers/functions';

function ContentHistoryPagamRemovido(props) {
  const pagamentosColumn = [
    {
      dataField: 'tipo', text: 'Status',
    },
    {
      dataField: 'formaPagamento', text: 'Forma',
    },
    {
      dataField: 'valor', text: 'Valor',
      formatter: (cell) => (<>{formatMoney(cell)}</>),
    },
    {
      dataField: 'data', text: 'Data',
      formatter: (cell) => (<>{formatDate(cell)}</>),
    },
  ];

  return (
    <>
      <Session name="Pagamentos Removidos">
        <Table data={props.pagamentos || []} columns={pagamentosColumn} keyField='data' />
      </Session>
    </>
  );
}

export default ContentHistoryPagamRemovido;