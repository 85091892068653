import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import RequiredField from '../RequiredField';
registerLocale('ptBR', { ...ptBR, options: { ...ptBR.options, weekStartsOn: 1 } });

function InputDate(props) {
    return (
        <>
            <Form.Group>
                {
                    !props.disableLabel &&
                    <Form.Label>{props.name}{props.required ? <RequiredField /> : ''}</Form.Label>
                }                
                <DatePicker name={props.id} className="form-control" isClearable autoComplete="off"
                    selected={props.value} onChange={props.onChange} locale="ptBR"
                    showMonthDropdown showYearDropdown fixedHeight
                    onCalendarOpen={() => {document.activeElement.blur()}}
                    showTimeSelect={false} dateFormat="dd/MM/yy"
                    withPortal placeholderText={props.disabled ? '' : `Inserir ${props.name}`}
                    disabled={props.disabled} required={props.required}
                />
                <Form.Control style={{display: 'none'}} value={props.value} required={props.required} />
                <Form.Control.Feedback type="invalid">
                    Campo {props.name} inválido
                </Form.Control.Feedback>
                {
                    props.additionalText &&
                    <Form.Text className="text-muted">
                        {props.additionalText}
                    </Form.Text>
                }
            </Form.Group>
        </>

    );
}

export default InputDate;