import React from 'react';
import InputSelect from '../../atoms/InputSelect';

const tiposCriacao = [
    { key: 'Agendado', value: 'Agendado' }, 
    { key: 'Reservado', value: 'Reservado' }
];

const tiposEdicao = [
    { key: 'Agendado', value: 'Agendado' }, 
    { key: 'Reservado', value: 'Reservado' },
    { key: 'Realizado', value: 'Realizado' },
    { key: 'Cancelado', value: 'Cancelado' }
];

function InputSelectStatusCompromisso(props) {
    return (
        <>
            <InputSelect {...props} options={props.criacao ? tiposCriacao : tiposEdicao} />
        </>
    );
}

export default InputSelectStatusCompromisso;