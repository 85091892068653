import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ButtonFile from '../../atoms/ButtonFile';
import Session from '../../../components/atoms/Session';
import { FaPlusCircle } from 'react-icons/fa';
import { formatDateTime } from '../../../helpers/functions';
import Table from '../../../components/atoms/Table';
import { optionsFormatterDownloadFunction, optionsFormatterRemoveFunction } from '../../../helpers/tableFunctions';

function DocSession(props) {

  let docsColumn = [
    {
      dataField: 'ident', hidden: true
    },
    {
      dataField: 'nome', text: 'Nome', sort: true,
    },
    {
      dataField: 'dataHoraEnvio', text: 'Data Envio', sort: true,
      formatter: (cell) => (<>{formatDateTime(cell)}</>),
    }
  ];

  if (!props.editable) {
    docsColumn.push({
      dataField: '_id', text: 'Opções',
      formatter: (cel, row) => (<>{optionsFormatterDownloadFunction(() => { props.getDocument(row.ident) })}</>),
      editable: false
    });
  } else {
    docsColumn.push({
      dataField: '_id', text: 'Opções',
      formatter: (cel, row) => (<>{optionsFormatterRemoveFunction(() => { props.removeDocument(row.ident) })}</>),
      editable: false
    });
  }

  return (
    <>
      <Session name="Documentos">
        <Row>
          <Col xs={8}>
            <>
              <Row>
                <Col>
                  <p><b>{(props.inputs.docs || []).length}</b> Arquivo(s)</p>
                </Col>
              </Row>
            </>
          </Col>
          <Col>
            {props.editable &&
              <ButtonFile className="btn float-right" variant="secondary" type="file"
                onFileLoad={(file) => props.addDocument(file)}>
                <FaPlusCircle />
              </ButtonFile>
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <Table data={props.inputs.docs || []} columns={docsColumn} keyField='ident'
              defaultSorted={[{ dataField: 'nome', order: 'asc' }]} />
          </Col>
        </Row>
      </Session>
    </>
  );
}

export default DocSession;