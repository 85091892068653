import React from 'react';
import { Modal, Row, Col, Container } from 'react-bootstrap';

function ModalTaskInconsistent(props) {

  function renderItem(item, i) {
    return (
      <>
        <Row>
          <Col>
            <li><h6>{item}</h6></li>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal show={props.show} onHide={props.onHide} size="xl"
      aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="fundo" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          ATENÇÃO
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fundo">
        <Row>
          <Container className="container container-nobars">
            <p style={{marginLeft: '2%'}}>
              <b>Pendência(s):</b>
              <br/><br/>
              <ul>
              {props.inconsistencias?.map(renderItem)}
              </ul>
            </p>
          </Container>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default ModalTaskInconsistent;