import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Container, Card, Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import { getDashboardInfos } from '../../../services/General';
import { useAlert } from 'react-alert';
import CardDashboard from '../../../components/atoms/CardDashboard';
import Table from '../../../components/atoms/Table';
import { formatDateTime, formatDate, formatMoney, formatDateDay, convertConfigToBoolean } from '../../../helpers/functions';
import { optionsFormatter } from '../../../helpers/tableFunctions';
import { getUserSession, getConfigCompany } from '../../../helpers/authHelper';
import InputDateSearch from '../../../components/atoms/InputDateSearch';
import ModalListTasks from '../../../components/organisms/ModalListTasks';
import { FaPlus, FaList, FaFileInvoice, FaRegCalendarPlus, FaNewspaper }
  from 'react-icons/fa';
import { searchTasks } from '../../../services/Tasks';
import { searchReminders } from '../../../services/Reminders';
import NotificationBadge, { Effect } from 'react-notification-badge';

function Dashboard(props) {

  const [loading, setLoading] = useState(true);
  const [infos, setInfos] = useState({});
  const [findDate, setFindDate] = useState();
  const [infosSearch, setInfosSearch] = useState([]);
  const [listModalShow, setListModalShow] = useState(false);
  const alert = useAlert();
  const userSession = getUserSession();

  const pagamentoHabilitado = convertConfigToBoolean(getConfigCompany("PAGAMENTO_HABILITADO"));

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {

      const resultInfos = await getDashboardInfos(alert);
      if (resultInfos) {
        setInfos(resultInfos);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const search = async (date) => {
    setLoading(true);
    const resultTasks = searchTasks({ dataHoraInicio: date, dataHoraFim: date }, alert);
    const resultReminders = searchReminders({ dataHoraInicio: date, dataHoraFim: date }, alert);
    const infosSearch = { tasks: await resultTasks, reminders: await resultReminders };
    if (infosSearch) {
      setInfosSearch(infosSearch);
    }
    setListModalShow(true);
    setLoading(false);
  }

  const closeModalTasks = () => {
    setListModalShow(false);
    setFindDate();
  }

  const tasksColumn = [
    {
      dataField: '_id', hidden: true
    },
    {
      dataField: 'tipo', text: 'Tipo', sort: true,
    },
    {
      dataField: 'dataHoraInicio', text: 'Início', sort: true,
      formatter: (cell) => (<>{formatDateTime(cell)}</>),
    },
    {
      dataField: 'dataHoraFim', text: 'Fim', sort: true,
      formatter: (cell) => (<>{formatDateTime(cell)}</>),
    },
    {
      dataField: 'nome', text: 'Nome', sort: true,
    },
    {
      dataField: 'endereco', text: 'Endereço', sort: true,
    },
    {
      dataField: '_id', text: 'Opções',
      formatter: (cell) => (<>{optionsFormatter(cell, 'task')}</>),
    }
  ];

  return (
    <Template loading={loading}>
      <Container className="container container-nobars">
        <Card className="fundo">
          <Card.Body className="min-margin">
            <Row>
              <Col>
                <CardDashboard header="Ações">
                  <Button href="/task/inconsistents" variant="secondary"
                    style={{ marginBottom: '10px' }}>
                    <FaFileInvoice /> Inconsistências
                    <NotificationBadge count={infos?.qtdCompInconsistentes || 0} effect={Effect.SCALE}
                      style={{ top: '-35px', right: '-20px' }} />
                  </Button>
                </CardDashboard>
                <CardDashboard header="Próximo Compromisso">
                  {infos.proximoCompromissoInicio &&
                    <h6>
                      {formatDateTime(infos.proximoCompromissoInicio)} - {formatDateTime(infos.proximoCompromissoFim)}
                    </h6>
                  }
                  {!infos.proximoCompromissoInicio &&
                    <h6>Sem dados!</h6>
                  }
                </CardDashboard>
                <CardDashboard header="Próximo Local">
                  {infos.proximoLocal &&
                    <h6>{infos.proximoLocal}</h6>
                  }
                  {!infos.proximoLocal &&
                    <h6>Sem dados!</h6>
                  }
                </CardDashboard>
              </Col>
              <Col>
                <CardDashboard header="Frequentes">
                  <ButtonGroup style={{ marginBottom: '10px' }}>
                    <Button href="/task/new" variant="success">
                      <FaPlus style={{ marginTop: '-30%' }} />
                    </Button>
                    <Button href="/tasks" variant="primary">
                      <FaList style={{ marginTop: '-30%' }} />
                    </Button>
                    <Button href="/reminder/new" variant="warning">
                      <FaRegCalendarPlus style={{ marginTop: '-30%' }} />
                    </Button>
                    {(userSession?.perfil === "ADMIN_EMPRESA" || userSession?.perfil === "ADMIN") &&
                      <Button href="/reports" variant="info">
                        <FaNewspaper style={{ marginTop: '-30%' }} />
                      </Button>
                    }
                  </ButtonGroup>
                </CardDashboard>
                {pagamentoHabilitado &&
                  <CardDashboard header="Próximo Pagamento">
                    {infos.proximoPagamento &&
                      <h6>{formatDate(infos.proximoPagamento.data)} - {formatMoney(infos.proximoPagamento.valor)}</h6>
                    }
                    {!infos.proximoPagamento &&
                      <h6>Sem dados!</h6>
                    }
                  </CardDashboard>
                }
                {!pagamentoHabilitado &&
                  <CardDashboard header="Nome Compromisso">
                    {infos.proximoCompromissoNome &&
                      <h6>{infos.proximoCompromissoNome}</h6>
                    }
                    {!infos.proximoCompromissoNome &&
                      <h6>Sem dados!</h6>
                    }
                  </CardDashboard>
                }
                <CardDashboard header="Próxima Data Livre">
                  {infos.proximaDataLivre &&
                    <h6>{formatDateDay(infos.proximaDataLivre)}</h6>
                  }
                  {!infos.proximaDataLivre &&
                    <h6>Sem dados!</h6>
                  }
                </CardDashboard>
              </Col>
              <Col style={{ textAlign: 'center', marginTop: '5px' }}>
                <InputDateSearch disableLabel id="date" name="Data"
                  datasOcupadas={infos.datasOcupadas} datasLembretes={infos.datasLembretes}
                  onChange={date => { setFindDate(date); search(date) }} value={findDate || ''} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="fundo" style={{ marginTop: '15px' }}>
          <Card.Header as="h6">5 Próximos Compromissos</Card.Header>
          <Card.Body className="min-margin">
            <Table data={infos.compromissos || []} columns={tasksColumn} keyField='_id'
              defaultSorted={[{ dataField: 'dataHoraInicio', order: 'asc' }]} />
          </Card.Body>
        </Card>
      </Container>
      <ModalListTasks show={listModalShow} itens={infosSearch} date={findDate} onHide={() => closeModalTasks()} />
    </Template>
  );
}

export default Dashboard;