import React from 'react';

function Logo(props) {

    if (props.miniatura) {
        return (
            <div>
                <h1 style={{
                    fontFamily: 'DancingScript',
                    FiraSanstextAlign: 'center',
                    marginTop: '-10px',
                    fontSize: 22,
                    transform: 'rotate(-6deg)'
                }}>
                    Agenda
                </h1>
                <h1 style={{
                    textAlign: 'right',
                    marginRight: '2%',
                    marginBottom: '-15%',
                    marginTop: '-15%',
                    fontSize: 5,
                    whiteSpace: 'pre'
                }}>
                    H E C T E C H
                </h1>
            </div>
        );
    } else {
        return (
            <div style={{ marginTop: '10%' }}>
                <h1 style={{
                    fontFamily: 'DancingScript',
                    FiraSanstextAlign: 'center',
                    marginTop: '-12%',
                    fontSize: 125,
                    transform: 'rotate(-6deg)'
                }}>
                    Agenda
                </h1>
                <h1 style={{
                    textAlign: 'right',
                    marginTop: '-3%',
                    marginRight: '10%',
                    fontSize: 28,
                    whiteSpace: 'pre'
                }}>
                    H E C T E C H
                </h1>
            </div>
        );
    }
}

Logo.defaultProps = {
    miniatura: false
}

export default Logo;