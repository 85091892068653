import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import InputDate from '../../atoms/InputDate';

function ModalFilterDates(props) {

  const [dataHoraInicio, setDataHoraInicio] = useState();
  const [dataHoraFim, setDataHoraFim] = useState();

  const filter = async () => {
    props.filterFunction({
      dataHoraInicio: dataHoraInicio, dataHoraFim: dataHoraFim
    });
  }

  const clear = () => {
    setDataHoraInicio();
    setDataHoraFim();
  }

  return (
    <Modal className="fundo" show={props.show} onHide={() => { clear(); props.onHide(); }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="fundo" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Filtrar Datas
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fundo">
        <Row>
          <Col>
            <InputDate id="dataHoraInicio" name="Data De" onChange={date => setDataHoraInicio(date)} value={dataHoraInicio || ''} />
          </Col>
          <Col>
            <InputDate id="dataHoraFim" name="Data Até" onChange={date => setDataHoraFim(date)} value={dataHoraFim || ''} />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="fundo">
        <Button variant="secondary" className="float-left" onClick={() => clear()}>Limpar</Button>
        <Button variant="success" className="float-right" onClick={() => filter()}>Buscar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalFilterDates;