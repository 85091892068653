import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Button, Container, ButtonGroup } from 'react-bootstrap';
import { getCompanies } from '../../../services/Companies';
import { FaPlusCircle, FaSync, FaColumns } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import { formatDateTime } from '../../../helpers/functions';
import Table from '../../../components/atoms/Table';
import { optionsFormatterRepresent, rowFormatter } from '../../../helpers/tableFunctions';
import PageHeader from '../../../components/molecules/PageHeader';
import ModalSelectColumns from '../../../components/organisms/ModalSelectColumns';

function List() {

  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getCompanies(alert);
      if (result) {
        setCompanies(result);
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const refresh = async () => {
    setLoading(true);
    const result = await getCompanies(alert);
    if (result) {
      setCompanies(result);
      alert.success('Lista atualizada');
    }
    setLoading(false);
  };

  const initialColumnsProps = {
    nome: { name: 'Nome', visibility: true },
    dataHoraCriacao: { name: 'Criação', visibility: true },
    responsavel: { name: 'Criador', visibility: false },
    visivel: { name: 'Visível', visibility: true },
  };

  const [columnsProps, setColumnsProps] = useState(initialColumnsProps);
  const [selectColumnsModalShow, setSelectColumnsModalShow] = useState(false);

  const companiesColumn = [
    {
      dataField: '_id', text: '#',
      formatter: (cell, row, rowIndex) => (<>{rowFormatter(rowIndex, companies, row)}</>)
    },
    {
      dataField: 'nome', text: columnsProps['nome'].name, sort: true,
      hidden: !columnsProps['nome'].visibility,
    },
    {
      dataField: 'dataHoraCriacao', text: columnsProps['dataHoraCriacao'].name, sort: true,
      hidden: !columnsProps['dataHoraCriacao'].visibility,
      formatter: (cell) => (<>{formatDateTime(cell)}</>),
    },
    {
      dataField: 'responsavel.nome', text: columnsProps['responsavel'].name, sort: true,
      hidden: !columnsProps['responsavel'].visibility,
    },
    {
      dataField: 'visivel', text: columnsProps['visivel'].name, sort: true,
      hidden: !columnsProps['visivel'].visibility,
    },
    {
      dataField: '_id', text: 'Opções',
      formatter: (cell) => (<>{optionsFormatterRepresent(cell, 'company')}</>),
    }
  ];
  
  return (
    <Template loading={loading}>
      <PageHeader tittle="Empresas">
        <ButtonGroup className="float-right">
          <Button variant="success" href="/company/new">
            <FaPlusCircle />
          </Button>
          <Button variant="primary" onClick={() => refresh()}>
            <FaSync />
          </Button>
          <Button variant="secondary" onClick={() => setSelectColumnsModalShow(true)}>
            <FaColumns />
          </Button>
        </ButtonGroup>
      </PageHeader>
      <Row>
        <Container className="container container-nobars">
          <Table data={companies} columns={companiesColumn} keyField='_id'
            defaultSorted={[{ dataField: 'nome', order: 'asc' }]} />
        </Container>
      </Row>
      <ModalSelectColumns show={selectColumnsModalShow} columnsProps={columnsProps}
        setColumnsProps={setColumnsProps} initialColumnsProps={initialColumnsProps}
        onHide={() => setSelectColumnsModalShow(false)} />
    </Template>
  );
}

export default List;