import { callGetMethod, callDeleteMethod, callPostMethod, callPutMethod } from '../../helpers/serviceHelper';
import * as SERVICES from '../../config/serviceConstants';

export const getTasks = async (alert) => {
    try {
        const tasks = await callGetMethod(SERVICES.TASKS);
        return tasks;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getTask = async (id, alert) => {
    try {
        const task = await callGetMethod(SERVICES.TASKS, id);
        return task;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const removeTask = async (id, alert) => {
    try {
        const response = await callDeleteMethod(SERVICES.TASKS, id);
        return response;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const registerTask = async (body, alert, addValidationError) => {
    try {
        const task = await callPostMethod(SERVICES.TASKS, body);
        return task;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const editTask = async (id, body, alert, addValidationError) => {
    try {
        const task = await callPutMethod(SERVICES.TASKS, id, body);
        return task;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const searchTasks = async (body, alert) => {
    try {
        const tasks = await callPostMethod(SERVICES.TASKS_SEARCH, body);
        return tasks;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getTaskHistory = async (id, alert) => {
    try {
        const task = await callGetMethod(SERVICES.TASKS_HISTORY, id);
        return task;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}