import { callGetMethod } from '../../helpers/serviceHelper';
import * as SERVICES from '../../config/serviceConstants';

export const getDashboardInfos = async (alert) => {
    try {
        const infos = await callGetMethod(SERVICES.DASHBOARD);
        return infos;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}