import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('ptBR', { ...ptBR, options: { ...ptBR.options, weekStartsOn: 1 } });

function InputDateTable(props) {
    return (
        <>
            <Form.Group>
                <DatePicker className="form-control" autoComplete="off"
                    selected={props.value ? new Date(props.value) : null} onChange={props.onUpdate}
                    startOpen={true} onCalendarClose={() => props.onUpdate(props.value)}
                    showTimeSelect={false} dateFormat="dd/MM/yy" locale="ptBR"
                    showMonthDropdown showYearDropdown fixedHeight
                    withPortal placeholderText={`Inserir Data`}
                />
            </Form.Group>
        </>
    );
}

export default InputDateTable;