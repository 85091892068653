import React from 'react';
import ContentHistoryMudancas from '../ContentHistoryMudancas';
import ContentHistoryMudancaPagam from '../ContentHistoryMudancaPagam';
import ContentHistoryPagamAdicionado from '../ContentHistoryPagamAdicionado';
import ContentHistoryPagamRemovido from '../ContentHistoryPagamRemovido';
import { getConfigCompany } from '../../../helpers/authHelper';
import { convertConfigToBoolean } from '../../../helpers/functions';

function ContentHistory(props) {

  const pagamentoHabilitado = convertConfigToBoolean(getConfigCompany("PAGAMENTO_HABILITADO"));

  return (
    <>
      <ContentHistoryMudancas mudancas={props.history.mudancas} />
      {pagamentoHabilitado &&
        <>
          <ContentHistoryMudancaPagam pagamentos={props.history.mudancasPagamento} />
          <ContentHistoryPagamAdicionado pagamentos={props.history.pagamentosAdicionados} />
          <ContentHistoryPagamRemovido pagamentos={props.history.pagamentosRemovidos} />
        </>
      }
    </>
  );
}

export default ContentHistory;