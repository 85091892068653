import React from 'react';
import InputSelect from '../../atoms/InputSelect';
import { getConfigCompany } from '../../../helpers/authHelper';
import { convertConfigToItensSelect } from '../../../helpers/functions';

const tiposPadrao = [
    { key: 'Reunião', value: 'Reunião' },
    { key: 'Bloqueio', value: 'Bloqueio' }
];

function InputSelectTipoCompromisso(props) {
    const config = getConfigCompany("TIPOS_COMPROMISSO");
    const tipos = convertConfigToItensSelect(config);
    return (
        <>
            <InputSelect {...props} options={tipos ? tipos : tiposPadrao} />
        </>
    );
}

export default InputSelectTipoCompromisso;