import md5 from 'md5';
import { callGetMethod, callPostMethod, callPutMethod, callDeleteMethod } from '../../helpers/serviceHelper';
import * as SERVICES from '../../config/serviceConstants';

const senhaPadrao = "12345678";

export const getUser = async (id, alert) => {
    try {
        const user = await callGetMethod(SERVICES.ADMIN_USERS, id);
        return user;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getUsers = async (alert) => {
    try {
        const users = await callGetMethod(SERVICES.ADMIN_USERS);
        return users;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const editUser = async (id, body, alert, addValidationError) => {
    try {
        const user = await callPutMethod(SERVICES.ADMIN_USERS, id, body);
        return user;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const removeUser = async (id, alert) => {
    try {
        const response = await callDeleteMethod(SERVICES.ADMIN_USERS, id);
        return response;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const registerUser = async (body, alert, addValidationError) => {
    try {
        const user = await callPostMethod(SERVICES.ADMIN_USERS, {...body, senha: md5(senhaPadrao)});
        return user;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const representCompany = async (id, alert) => {
    try {
        const response = await callPostMethod(SERVICES.ADMIN_REPRESENT, { idEmpresa: id });
        return response;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}