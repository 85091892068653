import React, { useState } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col, Form } from 'react-bootstrap';
import { registerReminder } from '../../../services/Reminders';
import InputTextArea from '../../../components/atoms/InputTextArea';
import InputDate from '../../../components/atoms/InputDate';
import useForm from '../../../helpers/formHelper';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBar from '../../../components/molecules/ButtonBar';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';

function New(props) {

  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const [data, setData] = useState();
  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const addValidationError = (validationError) => {
    let validationsError = validationErrors.slice();
    validationsError.push(validationError);
    setValidationErrors(validationsError);
  }

  const register = async () => {
    setLoading(true);

    let reminder = {
      data: data,
      nota: inputs.nota
    };

    const response = await registerReminder(reminder, alert, addValidationError);

    if (response) {
      if (response._id) {
        props.history.push(`/reminder/detail/${response._id}`);
      } else {
        props.history.push(`/reminders`);
      }
      alert.success('Lembrete criado com sucesso');
    }
    setLoading(false);
  }

  const { inputs, handleInputChange, validateInputs } = useForm();

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const errors = validateInputs({ page: 'createReminder', data });
      if (errors && errors.length > 0) {
        event.stopPropagation();
        setValidationErrors(errors);
      } else {
        register();
      }
    }

    setValidated(true);
  };

  return (
    <Template validationErrors={validationErrors} clearErrosFunc={() => setValidationErrors([])}
      loading={loading}>
      <PageHeader tittle="Criar Lembrete" />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <InputDate id="data" name="Data"
              onChange={date => setData(date)} value={data || ''} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputTextArea id="nota" name="Notas" value={inputs.nota || ''}
              onChange={handleInputChange} />
          </Col>
        </Row>
        <ButtonBar>
          <ButtonSubmit variant="success" type="submit">Criar</ButtonSubmit>
        </ButtonBar>
      </Form>
    </Template>
  );
}

export default New;