import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import { getUser, removeUser } from '../../../services/Users';
import { getIdFromUrlLocationPathname, isRemoveUrlLocation, formatDateTime } from '../../../helpers/functions';
import VerticalSpace from '../../../components/atoms/VerticalSpace';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBack from '../../../components/atoms/ButtonBack';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import Session from '../../../components/atoms/Session';
import ModalConfirmRemove from '../../../components/organisms/ModalConfirmRemove';

function Detail(props) {

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const id = getIdFromUrlLocationPathname(props.history.location.pathname);
  const isRemove = isRemoveUrlLocation(props.history.location);
  const alert = useAlert();

  const [confirmRemoveModalShow, setConfirmRemoveModalShow] = useState(isRemove);

  const remove = async () => {
    setLoading(true);
    const response = await removeUser(id, alert);
    if (response) {
      props.history.push('/users');
      alert.success('Usuário removido com sucesso');
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getUser(id, alert);
      if (result) {
        setUser(result);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  return (
    <Template loading={loading}>
      <PageHeader tittle="Detalhes do usuário" />
      <Row>
        <Col>
          <InputText name="Login"
            value={user.login || ''} disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText name="Nome"
            value={user.nome || ''} disabled />
        </Col>
        <Col>
          <InputText name="Sobrenome"
            value={user.sobrenome || ''} disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputText name="Perfil"
            value={user.perfil || ''} disabled />
        </Col>
        <Col>
          <InputText name="Visível"
            value={user.visivel || ''} disabled />
        </Col>
      </Row>
      <Session name="Informações">
        <Row>
          <Col>
            <InputText name="Criado Por" value={user?.responsavel?.nome || ''} disabled />
          </Col>
          <Col>
            <InputText name="Data Criação"
              value={user.dataHoraCriacao ? formatDateTime(user.dataHoraCriacao) : ''} disabled />
          </Col>
        </Row>
      </Session>
      <VerticalSpace />
      <Row>
        <Col style={{ paddingRight: '5px' }}>
          <ButtonBack />
        </Col>
        <Col className="col-md-1" style={{ paddingRight: '5px' }}>
          <ButtonSubmit className="center-block" variant="warning"
            onClick={() => { props.history.push(`/user/edit/${id}`) }}>Editar</ButtonSubmit>
        </Col>
        <Col style={{ paddingRight: '5px' }}>
          <ButtonSubmit className="float-right" variant="danger"
            onClick={() => { setConfirmRemoveModalShow(true) }}>Remover</ButtonSubmit>
        </Col>
      </Row>
      <ModalConfirmRemove show={confirmRemoveModalShow} onHide={() => setConfirmRemoveModalShow(false)}
        onConfirm={() => { remove() }} />
    </Template>
  );
}

export default Detail;