import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PaymentSession from '../../../components/organisms/PaymentSession';
import Template from '../../../components/organisms/Template';
import { Row, Col, Form } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import InputTextArea from '../../../components/atoms/InputTextArea';
import ButtonBack from '../../../components/atoms/ButtonBack';
import InputDateTime from '../../../components/atoms/InputDateTime';
import Session from '../../../components/atoms/Session';
import { getTask, editTask } from '../../../services/Tasks';
import { removeDoc, saveDoc } from '../../../services/Docs';
import { getIdFromUrlLocationPathname, formatDateTime, deepEqual } from '../../../helpers/functions';
import useForm from '../../../helpers/formHelper';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBar from '../../../components/molecules/ButtonBar';
import InputSelectTipoCompromisso from '../../../components/molecules/InputSelectTipoCompromisso';
import InputSelectStatusCompromisso from '../../../components/molecules/InputSelectStatusCompromisso';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import ModalInsertPayment from '../../../components/organisms/ModalInsertPayment';
import VerticalSpace from '../../../components/atoms/VerticalSpace';
import DocSession from '../../../components/organisms/DocSession';

function Edit(props) {

  const [loading, setLoading] = useState(true);
  const alert = useAlert();

  const [dataHoraInicio, setDataHoraInicio] = useState();
  const [dataHoraFim, setDataHoraFim] = useState();
  const [bloqueio, setBloqueio] = useState(false);

  const [insertPaymentModalShow, setInsertPaymentModalShow] = useState(false);

  const [inconsistencias, setInconsistencias] = useState([]);

  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const { inputs, handleInputChange, setInputs, validateInputs } = useForm();
  const [inputsInicial, setInputsInicial] = useState({});

  const id = getIdFromUrlLocationPathname(props.history.location.pathname);

  const addValidationError = (validationError) => {
    let validationsError = validationErrors.slice();
    validationsError.push(validationError);
    setValidationErrors(validationsError);
  }

  const edit = async () => {
    setLoading(true);
    const response = await editTask(id, {
      tipo: inputs.tipo,
      dataHoraInicio: dataHoraInicio,
      dataHoraFim: dataHoraFim,
      nome: inputs.nome,
      telefone: inputs.telefone,
      status: inputs.status,
      observacoes: inputs.observacoes,
      endereco: inputs.endereco,
      pagamentos: inputs.pagamentos
    }, alert, addValidationError);

    if (response) {
      props.history.push(`/task/detail/${id}`);
      alert.success('Compromisso editado com sucesso');
    }
    setLoading(false);
  };

  const savePayment = async (payment) => {
    setLoading(true);
    if (payment) {
      payment.ident = uuidv4();
      let payments = inputs.pagamentos || [];
      payments.push(payment);
      setInputs({ ...inputs, pagamentos: payments });
    }
    setInsertPaymentModalShow(false);
    setLoading(false);
  };

  const removeDocument = async (fileIdent) => {
    setLoading(true);
    const fetchData = async () => {
      const result = await removeDoc({ taskId: id, fileIdent: fileIdent }, alert);
      if (result) {
        alert.success('Documento removido com sucesso');
        loadPage();
      }
      setLoading(false);
    };

    fetchData();
  };

  const addDocument = async (file) => {
    setLoading(true);
    const fetchData = async () => {
      const fileObj = await convertFileBase64(file);
      const fileBody = { taskId: id, fileIdent: uuidv4(), fileName: fileObj.name, fileType: fileObj.type, fileData: fileObj.data };
      const result = await saveDoc(fileBody, alert);
      if (result) {
        alert.success('Documento enviado com sucesso');
        loadPage();
      }

      setLoading(false);
    };

    fetchData();
  };

  const convertFileBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve({ name: file.name, type: file.type, data: fileReader.result.substring(fileReader.result.indexOf(',')+1) });
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  };

  const loadPage = async () => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getTask(id, alert);
      if (result) {
        setInputs({ ...result });
        setInputsInicial({ ...result });
      }
      if (result && result.dataHoraInicio) {
        setDataHoraInicio(new Date(result.dataHoraInicio));
      }
      if (result && result.dataHoraFim) {
        setDataHoraFim(new Date(result.dataHoraFim));
      }
      setLoading(false);
    };

    fetchData();
  };

  useEffect(() => {
    loadPage();
  }, [id, setInputs, setInputsInicial]);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const errors = validateInputs({ page: 'edit', dataHoraInicio, dataHoraFim });
      if (errors && errors.length > 0) {
        event.stopPropagation();
        setValidationErrors(errors);
      } else {
        edit();
      }
    }

    setValidated(true);
  };

  useEffect(() => {
    if (inputs) {
      if (inputs.tipo === "Bloqueio") {
        setBloqueio(true);
      } else {
        setBloqueio(false);
      }
    }
  }, [inputs, setBloqueio]);

  useEffect(() => {
    if (inputs && inputs.inconsistenciasCompromisso && inputs.inconsistenciasPagamento) {
      setInconsistencias(inputs.inconsistenciasCompromisso.concat(inputs.inconsistenciasPagamento));
    }
  }, [inputs._id, setInconsistencias]);

  const checkNotSaved = () => {
    return !deepEqual(inputs, inputsInicial);
  }

  if (inputs.visivel) {
    return (
      <Template inconsistencias={inconsistencias} clearInconsFunc={() => setInconsistencias([])}
        validationErrors={validationErrors} clearErrosFunc={() => setValidationErrors([])}
        loading={loading}>
        <PageHeader tittle="Editar compromisso" />
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col>
              <InputDateTime id="dataHoraInicio" name="Início" value={dataHoraInicio || ''}
                onChange={date => setDataHoraInicio(date)} required />
            </Col>
            <Col>
              <InputDateTime id="dataHoraFim" name="Fim" value={dataHoraFim || ''}
                onChange={date => setDataHoraFim(date)} required />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputSelectTipoCompromisso id="tipo" name="Tipo" value={inputs.tipo || ''}
                onChange={handleInputChange} required />
            </Col>
            <Col>
              <InputSelectStatusCompromisso id="status" name="Status Compromisso"
                value={inputs.status || ''} onChange={handleInputChange} required />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputText id="nome" name="Nome" value={inputs.nome || ''}
                onChange={handleInputChange} required />
            </Col>
            <Col>
              <InputText id="telefone" name="Telefone" value={inputs.telefone || ''}
                onChange={handleInputChange} required={!bloqueio} />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputText id="endereco" name="Endereço" value={inputs.endereco || ''}
                onChange={handleInputChange} required={!bloqueio} />
            </Col>
          </Row>
          <Row>
            <Col>
              <InputTextArea id="observacoes" name="Notas" value={inputs.observacoes || ''}
                onChange={handleInputChange} />
            </Col>
          </Row>
          <PaymentSession inputs={inputs} setInputs={(value) => setInputs(value)} setLoading={(value) => setLoading(value)}
            setInsertPaymentModalShow={(value) => setInsertPaymentModalShow(value)} editable={true} />
          <DocSession inputs={inputs} editable={true} addDocument={(file) => addDocument(file)} removeDocument={(fileIdent) => removeDocument(fileIdent)} />
          <Session name="Informações">
            <Row>
              <Col>
                <InputText name="Criado Por" value={inputs?.responsavel?.nome || ''} disabled />
              </Col>
              <Col>
                <InputText name="Data Criação"
                  value={inputs.dataHoraCriacao ? formatDateTime(inputs.dataHoraCriacao) : ''} disabled />
              </Col>
            </Row>
          </Session>
          <ButtonBar edit={true} notSaved={checkNotSaved()}>
            <ButtonSubmit variant="success" type="submit">Salvar</ButtonSubmit>
          </ButtonBar>
        </Form>
        <ModalInsertPayment show={insertPaymentModalShow}
          onHide={() => setInsertPaymentModalShow(false)}
          saveFunction={(payment) => savePayment(payment)} />
      </Template>
    );
  } else {
    return (
      <Template validationErrors={validationErrors} loading={loading}>
        <PageHeader tittle="Editar compromisso" />
        <Row>
          <Col>
            <b>Não é possível editar um compromisso removido!</b>
          </Col>
        </Row>
        <VerticalSpace />
        <Row>
          <Col>
            <ButtonBack />
          </Col>
        </Row>
      </Template>
    );
  }
}

export default Edit;