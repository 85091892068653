import React from 'react';
import Session from '../../../components/atoms/Session';
import Table from '../../../components/atoms/Table';
import { tratarNomeCampo, tratarTiposCampos } from '../../../helpers/functions';

function ContentHistoryMudancas(props) {
  const mudancasColumn = [
    {
      dataField: 'nome', text: 'Campo',
      formatter: (cell) => (<>{tratarNomeCampo(cell)}</>),
    },
    {
      dataField: 'de', text: 'De', 
      formatter: (cell, row) => (<>{tratarTiposCampos(row.nome, cell)}</>),
    },
    {
      dataField: 'para', text: 'Para',
      formatter: (cell, row) => (<>{tratarTiposCampos(row.nome, cell)}</>),
    },
  ];

  return (
    <>
      <Session name="Alterações">
        <Table data={props.mudancas || []} columns={mudancasColumn} keyField='nome' />
      </Session>
    </>
  );
}

export default ContentHistoryMudancas;