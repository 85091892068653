import { ROUTER_PERMISSION } from '../config/routerPermission';

const getPermissions = (route) => {
    let permissions = ROUTER_PERMISSION[route];
    if (permissions) {
        return permissions;
    } else {
        const baseRoute = route.substring(0, route.lastIndexOf('/') + 1);
        permissions = ROUTER_PERMISSION[baseRoute + ':id'];
        if (permissions) {
            return permissions;
        } else {
            return [];
        }
    }
}

export const authorizedRoute = (route, profile) => {
    const permissions = getPermissions(route);
    if (permissions.length === 0 || profile === 'ADMIN' || permissions.includes(profile)) {
        return true;
    } else {
        return false;
    }
};