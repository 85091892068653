import { callGetMethod, callDeleteMethod, callPostMethod, callPutMethod } from '../../helpers/serviceHelper';
import * as SERVICES from '../../config/serviceConstants';

export const getCompanies = async (alert) => {
    try {
        const companies = await callGetMethod(SERVICES.COMPANIES);
        return companies;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getCompany = async (id, alert) => {
    try {
        const company = await callGetMethod(SERVICES.COMPANIES, id);
        return company;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const removeCompany = async (id, alert) => {
    try {
        const response = await callDeleteMethod(SERVICES.COMPANIES, id);
        return response;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const registerCompany = async (body, alert, addValidationError) => {
    try {
        const company = await callPostMethod(SERVICES.COMPANIES, body);
        return company;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const editCompany = async (id, body, alert, addValidationError) => {
    try {
        const company = await callPutMethod(SERVICES.COMPANIES, id, body);
        return company;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}