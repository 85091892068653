import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

function InputMoneyTable(props) {

    const [valor, setValor] = useState(props.value);

    const onChangeFunc = (event) => {
        const value = event && event.target ? event.target.value : valor;
        setValor(value);
    };

    const onBlurFunc = () => {
        props.onUpdate(valor);
    };

    return (
        <>
            <Form.Group>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>R$</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control min="0" max="99999" step="1" type="number" value={valor}
                        onChange={onChangeFunc} onBlur={onBlurFunc} autoFocus={true} />
                </InputGroup>
            </Form.Group>
        </>
    );
}

export default InputMoneyTable;