import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatMoney } from '../../../helpers/functions';

function ViewTotalPayments(props) {

    
    var valorTotal = 0;
    var totalPago = 0;
    var totalRestante = 0;
    props?.pagamentos?.forEach(pagamento => {
        if (pagamento) {
            valorTotal += Number(pagamento.valor);

            if (pagamento.tipo === 'Pago') {
                totalPago += Number(pagamento.valor);
            }
        }
    });

    totalRestante = valorTotal - totalPago;

    return (
        <>
        <Row>
            <Col>
                <p>Total: <b>{formatMoney(valorTotal)}</b></p>
            </Col>
            <Col>
                <p>Pago: <b>{formatMoney(totalPago)}</b></p>
            </Col>
            <Col>
                <p>Resta: <b>{formatMoney(totalRestante)}</b></p>
            </Col>
        </Row>
        </>
    );
}

export default ViewTotalPayments;