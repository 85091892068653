import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import { formatDateTime } from '../../../helpers/functions';

function renderItemHistory(row, i) {
  return (
    <Container>
      <Nav.Item>
        <Nav.Link eventKey={i}>{formatDateTime(row.dataHora)}</Nav.Link>
      </Nav.Item>
    </Container>
  );
}

function ListHistory(props) {
  return (
    <Nav variant="pills" className="flex-column">
      {props.rows && props.rows.map(renderItemHistory)}
    </Nav>
  );
}

export default ListHistory;