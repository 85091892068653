import React from 'react';
import { Form, Button } from 'react-bootstrap';

function ButtonText(props) {
    return (
        <>
            <Form.Group>
                <Form.Label>{props.name}</Form.Label>
                <br/>
                <Button {...props}>Abrir</Button>                
            </Form.Group>
        </>
    );
}

export default ButtonText;