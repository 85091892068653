import React from 'react';
import { Modal, Row, Col, Container } from 'react-bootstrap';
import Table from '../../atoms/Table';
import { optionsFormatter } from '../../../helpers/tableFunctions';
import { formatDateTime, formatDate } from '../../../helpers/functions';

function ModalListTasks(props) {

  const tasksColumn = [
    {
      dataField: '_id', hidden: true
    },
    {
      dataField: 'tipo', text: 'Tipo', sort: true,
    },
    {
      dataField: 'dataHoraInicio', text: 'Início', sort: true,
      formatter: (cell) => (<>{formatDateTime(cell)}</>),
    },
    {
      dataField: 'nome', text: 'Nome', sort: true,
    },
    {
      dataField: 'endereco', text: 'Endereço', sort: true,
    },
    {
      dataField: 'status', text: 'Status', sort: true,
    },
    {
      dataField: '_id', text: 'Opções',
      formatter: (cell) => (<>{optionsFormatter(cell, 'task')}</>),
    }
  ];

  const RemindersColumn = [
    {
      dataField: '_id', hidden: true
    },
    {
      dataField: 'nota', text: 'Notas', sort: true,
      formatter: (cell) => (<>{cell && cell.length > 80 ? cell.substring(0, 80) + "..." : cell}</>),
    },
    {
      dataField: '_id', text: 'Opções',
      formatter: (cell) => (<>{optionsFormatter(cell, 'reminder')}</>),
    }
  ];

  const hasTasks = props?.itens?.tasks && props.itens.tasks.length > 0 ? true : false;
  const hasReminders = props?.itens?.reminders && props.itens.reminders.length > 0 ? true : false;

  return (
    <Modal className="fundo" show={props.show} onHide={props.onHide} size="xl"
      aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className="fundo" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Compromissos em {formatDate(props.date)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fundo">
        {hasTasks &&
          <>
            <Row>
              <Container className="itens-centered">
                <h6>Compromissos:</h6>
              </Container>
            </Row>
            <Row>
              <Container className="container container-nobars">
                <Table data={props.itens.tasks || []} columns={tasksColumn} keyField='_id'
                  defaultSorted={[{ dataField: 'dataHoraInicio', order: 'asc' }]} />
              </Container>
            </Row>
          </>
        }
        {hasReminders &&
          <>
            <Row>
              <Container className="itens-centered">
                <h6>Lembretes:</h6>
              </Container>
            </Row>
            <Row>
              <Container className="container container-nobars">
                <Table data={props.itens.reminders || []} columns={RemindersColumn} keyField='_id'
                  defaultSorted={[{ dataField: 'data', order: 'asc' }]} />
              </Container>
            </Row>
          </>
        }
        {!hasTasks && !hasReminders &&
          <Row>
            <Col>
              <Container className="itens-centered">
                <h6>Sem dados!</h6>
              </Container>
            </Col>
          </Row>
        }
      </Modal.Body>
    </Modal>
  );
}

export default ModalListTasks;