import { callGetMethod, callDeleteMethod, callPostMethod, callPutMethod } from '../../helpers/serviceHelper';
import * as SERVICES from '../../config/serviceConstants';

export const getReminders = async (alert) => {
    try {
        const reminders = await callGetMethod(SERVICES.REMINDERS);
        return reminders;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const getReminder = async (id, alert) => {
    try {
        const reminder = await callGetMethod(SERVICES.REMINDERS, id);
        return reminder;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const searchReminders = async (body, alert) => {
    try {
        const reminders = await callPostMethod(SERVICES.REMINDERS_SEARCH, body);
        return reminders;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const removeReminder = async (id, alert) => {
    try {
        const response = await callDeleteMethod(SERVICES.REMINDERS, id);
        return response;
    } catch (error) {
        if (error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const registerReminder = async (body, alert, addValidationError) => {
    try {
        const reminder = await callPostMethod(SERVICES.REMINDERS, body);
        return reminder;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}

export const editReminder = async (id, body, alert, addValidationError) => {
    try {
        const reminder = await callPutMethod(SERVICES.REMINDERS, id, body);
        return reminder;
    } catch (error) {
        if (error && error.status === 409 && addValidationError) {
            addValidationError(error.data);
        } else if (error && error.data) {
            alert.error(error.data);
        } else {
            alert.error('Ocorreu um erro, por favor repita a operação');
        }
    }
}