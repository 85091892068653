import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Template from '../../../components/organisms/Template';
import { Row, Col, Form } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import InputTextArea from '../../../components/atoms/InputTextArea';
import InputDateTime from '../../../components/atoms/InputDateTime';
import { registerTask } from '../../../services/Tasks';
import useForm from '../../../helpers/formHelper';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBar from '../../../components/molecules/ButtonBar';
import InputSelectTipoCompromisso from '../../../components/molecules/InputSelectTipoCompromisso';
import InputSelectStatusCompromisso from '../../../components/molecules/InputSelectStatusCompromisso';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import PaymentSession from '../../../components/organisms/PaymentSession';
import ModalInsertPayment from '../../../components/organisms/ModalInsertPayment';

function New(props) {

  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const [dataHoraInicio, setDataHoraInicio] = useState();
  const [dataHoraFim, setDataHoraFim] = useState();
  const [bloqueio, setBloqueio] = useState(false);

  const [insertPaymentModalShow, setInsertPaymentModalShow] = useState(false);

  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const addValidationError = (validationError) => {
    let validationsError = validationErrors.slice();
    validationsError.push(validationError);
    setValidationErrors(validationsError);
  };

  const savePayment = async (payment) => {
    setLoading(true);
    if (payment) {
      payment.ident = uuidv4();
      let payments = inputs.pagamentos || [];
      payments.push(payment);
      setInputs({ ...inputs, pagamentos: payments });
    }
    setInsertPaymentModalShow(false);
    setLoading(false);
  };

  const register = async () => {
    setLoading(true);

    let pagamentos = [];

    await inputs.pagamentos?.forEach((pagamento) => {
      if (pagamento && pagamento.valor > 0) {
        pagamentos.push(pagamento);
      }
    });

    let task = {
      tipo: inputs.tipo,
      dataHoraInicio: dataHoraInicio,
      dataHoraFim: dataHoraFim,
      nome: inputs.nome,
      telefone: inputs.telefone,
      status: inputs.status,
      observacoes: inputs.observacoes,
      endereco: inputs.endereco,
      pagamentos: pagamentos
    };

    const response = await registerTask(task, alert, addValidationError);

    if (response) {
      if (response._id) {
        props.history.push(`/task/detail/${response._id}`);
      } else {
        props.history.push(`/tasks`);
      }
      alert.success('Compromisso criado com sucesso');
    }
    setLoading(false);
  };

  const { inputs, handleInputChange, setInputs, validateInputs } = useForm();

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const errors = validateInputs({ page: 'create', dataHoraInicio, dataHoraFim });
      if (errors && errors.length > 0) {
        event.stopPropagation();
        setValidationErrors(errors);
      } else {
        register();
      }
    }

    setValidated(true);
  };

  useEffect(() => {
    if (inputs) {
      if (inputs.tipo === "Bloqueio") {
        setBloqueio(true);
      } else {
        setBloqueio(false);
      }
    }
  }, [inputs, setBloqueio]);

  return (
    <Template validationErrors={validationErrors} clearErrosFunc={() => setValidationErrors([])}
      loading={loading}>
      <PageHeader tittle="Criar compromisso" />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <InputDateTime id="dataHoraInicio" name="Início"
              onChange={date => setDataHoraInicio(date)} value={dataHoraInicio || ''} required />
          </Col>
          <Col>
            <InputDateTime id="dataHoraFim" name="Fim"
              onChange={date => setDataHoraFim(date)} value={dataHoraFim || ''} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputSelectTipoCompromisso id="tipo" name="Tipo" value={inputs.tipo || ''}
              onChange={handleInputChange} required />
          </Col>
          <Col>
            <InputSelectStatusCompromisso criacao={true} id="status" name="Status"
              value={inputs.status || ''} onChange={handleInputChange} required />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="nome" name="Nome" value={inputs.nome || ''}
              onChange={handleInputChange} required />
          </Col>
          <Col>
            <InputText id="telefone" name="Telefone" value={inputs.telefone || ''}
              onChange={handleInputChange} required={!bloqueio} />
          </Col>
        </Row>
        <Row>
            <Col>
              <InputText id="endereco" name="Endereço" value={inputs.endereco || ''}
                onChange={handleInputChange} required={!bloqueio} />
            </Col>
          </Row>
        <Row>
          <Col>
            <InputTextArea id="observacoes" name="Notas" value={inputs.observacoes || ''}
              onChange={handleInputChange} />
          </Col>
        </Row>
        <PaymentSession inputs={inputs} setInputs={setInputs} setLoading={setLoading}
          setInsertPaymentModalShow={setInsertPaymentModalShow} editable={true} />
        <ButtonBar>
          <ButtonSubmit variant="success" type="submit">Criar</ButtonSubmit>
        </ButtonBar>
      </Form>
      <ModalInsertPayment show={insertPaymentModalShow}
        onHide={() => setInsertPaymentModalShow(false)}
        saveFunction={(payment) => savePayment(payment)} />
    </Template>
  );
}

export default New;