import React from 'react';
import VerticalSpace from '../../atoms/VerticalSpace';
import ButtonBack from '../../atoms/ButtonBack';
import ButtonBackEdit from '../../molecules/ButtonBackEdit';

function ButtonBar(props) {
  return (
    <>
      <VerticalSpace />
      {props.edit &&
        <ButtonBackEdit notSaved={props.notSaved} />
      }
      {!props.edit &&
        <ButtonBack />
      }
      {props.children}
    </>
  );
}

export default ButtonBar;