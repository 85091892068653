import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import { getCompany } from '../../../services/Companies';
import { representCompany } from '../../../services/Admin';
import { getIdFromUrlLocationPathname, formatDateTime } from '../../../helpers/functions';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBar from '../../../components/molecules/ButtonBar';
import VerticalSpace from '../../../components/atoms/VerticalSpace';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import Session from '../../../components/atoms/Session';

function RepresentCompany(props) {

  const [loading, setLoading] = useState(true);
  const alert = useAlert();

  const [company, setCompany] = useState({});
  const id = getIdFromUrlLocationPathname(props.history.location.pathname);

  const represent = async () => {
    setLoading(true);
    const response = await representCompany(id, alert);
    if (response) {
      props.history.push('/logout');
      alert.success('Representação incluída, faça login novamente.');
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getCompany(id, alert);
      if (result) {
        setCompany(result);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  return (
    <Template loading={loading}>
      <PageHeader tittle="Representar Empresa" />
      <Row>
        <Col>
          <h4>Deseja representar essa empresa?</h4>
        </Col>
      </Row>
      <VerticalSpace />
      <Row>
        <Col>
          <InputText name="Nome" value={company.nome || ''} disabled />
        </Col>
      </Row>
      <Session name="Informações">
        <Row>
          <Col>
            <InputText name="Criado Por" value={company?.responsavel?.nome || ''} disabled />
          </Col>
          <Col>
            <InputText name="Data Criação"
              value={company.dataHoraCriacao ? formatDateTime(company.dataHoraCriacao) : ''} disabled />
          </Col>
        </Row>
      </Session>
      <ButtonBar>
        <ButtonSubmit variant="success" onClick={() => { represent() }}>Representar</ButtonSubmit>
      </ButtonBar>
    </Template>
  );
}

export default RepresentCompany;