import React from 'react';
import Session from '../../../components/atoms/Session';
import Table from '../../../components/atoms/Table';
import { formatDate, tratarNomeCampoPagamento, tratarTiposCampos, formatMoney }
  from '../../../helpers/functions';

function ContentHistoryMudancaPagam(props) {

  const pagamentosColumn = [
    {
      dataField: 'pagamento.tipo', text: 'Status',
    },
    {
      dataField: 'pagamento.formaPagamento', text: 'Forma',
    },
    {
      dataField: 'pagamento.valor', text: 'Valor',
      formatter: (cell) => (<>{formatMoney(cell)}</>),
    },
    {
      dataField: 'pagamento.data', text: 'Data',
      formatter: (cell) => (<>{formatDate(cell)}</>),
    },
  ];

  const mudancasColumn = [
    {
      dataField: 'nome', text: 'Campo',
      formatter: (cell) => (<>{tratarNomeCampoPagamento(cell)}</>),
    },
    {
      dataField: 'de', text: 'De',
      formatter: (cell, row) => (<>{tratarTiposCampos(row.nome, cell)}</>),
    },
    {
      dataField: 'para', text: 'Para',
      formatter: (cell, row) => (<>{tratarTiposCampos(row.nome, cell)}</>),
    },
  ];

  var numMudancas = 0;
    props?.pagamentos?.forEach((pagamento) => {
      if (pagamento && pagamento.mudancas && pagamento.mudancas.length > 0) {
        numMudancas = numMudancas + 1;
      }
    });

  return (
    <>
      <Session name="Alterações de Pagamentos">
        {props.pagamentos?.map((pagamento) => {
          if (pagamento && pagamento.mudancas && pagamento.mudancas.length > 0) {
            return (
              <>
                <Session disableLabel={true}>
                  <Table data={[pagamento]} columns={pagamentosColumn} keyField='_id' />
                  <Table data={pagamento.mudancas} columns={mudancasColumn} keyField='nome' />
                </Session>
              </>
            );
          }
          return (<></>);
        })}
        {numMudancas === 0 &&
          <h6 style={{ textAlign: 'center' }}>Sem dados para exibir!</h6>
        }
      </Session>
    </>
  );
}

export default ContentHistoryMudancaPagam;