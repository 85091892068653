import React from 'react';
import Session from '../../../components/atoms/Session';
import Table from '../../../components/atoms/Table';
import { formatDateTime } from '../../../helpers/functions';

function ContentHistoryDocs(props) {
  const docsColumn = [
    {
      dataField: 'nome', text: 'Nome',
    },
    {
      dataField: 'dataHoraEnvio', text: 'Data Envio',
      formatter: (cell) => (<>{formatDateTime(cell)}</>),
    },
  ];

  return (
    <>
      <Session name="Documentos Adicionados">
        <Table data={props.adicionados || []} columns={docsColumn} keyField='dataHoraEnvio' />
      </Session>
      <Session name="Documentos Removidos">
        <Table data={props.removidos || []} columns={docsColumn} keyField='dataHoraEnvio' />
      </Session>
    </>
  );
}

export default ContentHistoryDocs;