import React from 'react';
import { Form, Card } from 'react-bootstrap';

function Session(props) {

    let margin = {};
    if (props.noMargin) {
        margin = { marginBottom: 0 };
    }

    return (
        <>
            <Form.Group style={margin}>
                {
                    !props.disableLabel &&
                    <Form.Label>{props.name}</Form.Label>
                }
                <Card className="fundo" variant={props.variant}>
                    <Card.Body className="min-margin">
                        {props.children}
                    </Card.Body>
                </Card>
            </Form.Group>
        </>
    );
}

Session.defaultProps = {
    variant: "secondary"
}

export default Session;