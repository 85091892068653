import React from 'react';
import Template from '../../../components/organisms/Template';
import Session from '../../../components/atoms/Session';
import { Container } from 'react-bootstrap';

function NotFound() {
  return (
    <Template>
      <Container>
        <Session>
          <h1>Ops!</h1>
          <p>Página não encontrada...</p>
        </Session>
      </Container>
    </Template>
  );
}

export default NotFound;