import React, { useEffect, useState } from 'react';
import Template from '../../../components/organisms/Template';
import { Form, Row, Col } from 'react-bootstrap';
import { userChangePassword } from '../../../services/Users';
import InputText from '../../../components/atoms/InputText';
import Session from '../../../components/atoms/Session';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBar from '../../../components/molecules/ButtonBar';
import { getLoggedUser } from '../../../services/Users';
import useForm from '../../../helpers/formHelper';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';

function Profile(props) {

  const [loading, setLoading] = useState(true);
  const alert = useAlert();

  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const { inputs, handleInputChange, setInputs, validateInputs } = useForm();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getLoggedUser(alert);
      if (result) {
        result.password = '';
        setInputs(result);
      }
      setLoading(false);
    };

    fetchData();
  }, [setInputs]);

  const edit = async () => {
    setLoading(true);
    const response = await userChangePassword(inputs._id, inputs.senha, alert);

    if (response) {
      props.history.push(`/profile`);
      alert.success('Senha editada com sucesso');
    }
    setLoading(false);
  };

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const errors = validateInputs({ page: 'profile' });
      if (errors && errors.length > 0) {
        event.stopPropagation();
        setValidationErrors(errors);
      } else {
        edit();
      }
    }

    setValidated(true);
  };

  return (
    <Template validationErrors={validationErrors} clearErrosFunc={() => setValidationErrors([])}
      loading={loading}>
      <PageHeader tittle="Perfil" />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <InputText id="id" name="ID" value={inputs._id || ''} disabled />
          </Col>
          <Col>
            <InputText id="login" name="Login" value={inputs.login || ''} onChange={handleInputChange} disabled />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="nome" name="Nome" value={inputs.nome || ''} onChange={handleInputChange} disabled />
          </Col>
          <Col>
            <InputText id="sobrenome" name="Sobrenome" value={inputs.sobrenome || ''} onChange={handleInputChange} disabled />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputText id="perfil" name="Perfil" value={inputs.perfil || ''} onChange={handleInputChange} disabled />
          </Col>
          <Col>
            <InputText id="empresa" name="Empresa" value={inputs.empresa?.nome || ''} onChange={handleInputChange} disabled />
          </Col>
        </Row>
        <Session name="Troca de Senha">
          <Col>
            <InputText id="senha" type="password" name="Senha" onChange={handleInputChange} value={inputs.senha || ''} required />
          </Col>
          <Col>
            <InputText id="senhaConfirmacao" type="password" name="Confirmação" onChange={handleInputChange} value={inputs.senhaConfirmacao || ''} required />
          </Col>
        </Session>
        <ButtonBar>
          <ButtonSubmit variant="success" type="submit">Salvar</ButtonSubmit>
        </ButtonBar>
      </Form>
    </Template>
  );
}

export default Profile;