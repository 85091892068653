import React from 'react';
import Chart from "react-apexcharts";

function BarChart(props) {

  const chart = {
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: props.categories,
        labels: {
          formatter: props.categoriesFormatter
        },
      },
      yaxis: {
        labels: {
          formatter: props.valuesFormatter
        },
      },
    },
    series: [
      {
        name: props.chartName,
        data: props.values
      }
    ]
  };

  return (
    <>
      <div className="mixed-chart">
        <Chart
          options={chart.options}
          series={chart.series}
          type="bar"
          height="350rem"
        />
      </div>
    </>
  );
}

export default BarChart;