import React, { useState, useEffect } from 'react';
import { getTasksPagamentosFuturosMensal } from '../../../services/Reports';
import Template from '../../../components/organisms/Template';
import { Row, Button, ButtonGroup, Container } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import { formatMoney, formatMonthNames } from '../../../helpers/functions';
import BarChart from '../../../components/organisms/BarChart';
import PageHeader from '../../../components/molecules/PageHeader';

function PagamentosFuturosMensal() {

  const [loading, setLoading] = useState(true);
  const [meses, setMeses] = useState([]);
  const [pagamentos, setPagamentos] = useState([]);
  const alert = useAlert();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getTasksPagamentosFuturosMensal(alert);
      if (result) {
        setMeses(result.map(value => (value.de)));
        setPagamentos(result.map(value => (value.valor)));
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const refresh = async () => {
    setLoading(true);
    const result = await getTasksPagamentosFuturosMensal(alert);
    if (result) {
      setMeses(result.map(value => (value.de)));
      setPagamentos(result.map(value => (value.valor)));
      alert.success('Dados Atualizados');
    }
    setLoading(false);
  };

  return (
    <Template loading={loading} onlyContent>
      <PageHeader tittle="Pagamentos Futuros Mensal" min>
        <ButtonGroup className="float-right">
          <Button variant="primary" onClick={() => refresh()}>
            <FaSync />
          </Button>
        </ButtonGroup>
      </PageHeader>
      <Row>
        <Container className="container container-nobars">
          <BarChart chartName="Pagamentos"
            categories={meses} categoriesFormatter={formatMonthNames}
            values={pagamentos} valuesFormatter={formatMoney} />
        </Container>
      </Row>
    </Template>
  );
}

export default PagamentosFuturosMensal;