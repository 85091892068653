import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import Logo from '../../atoms/Logo';

function Copyright() {
  return (
    <Container>
      <Navbar variant="dark" bg="dark" fixed="bottom" style={{paddingTop: 0, paddingBottom: 0}}>
      <Navbar.Text>{new Date().getFullYear()} - <a href='https://hectech.com.br'>hectech.com.br</a></Navbar.Text>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Logo miniatura={true} />
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default Copyright;