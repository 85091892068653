import React, { useState, useEffect } from 'react';
import { getBalancoPagamentos } from '../../../services/Reports';
import Template from '../../../components/organisms/Template';
import { Row, Col, Card, Button, ButtonGroup } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import { formatMoney } from '../../../helpers/functions';
import PieChart from '../../../components/organisms/PieChart';
import PageHeader from '../../../components/molecules/PageHeader';

function BalancoPagamentos() {

  const [loading, setLoading] = useState(true);
  const [pagamentos, setPagamentos] = useState({});
  const alert = useAlert();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getBalancoPagamentos(alert);
      if (result) {
        setPagamentos({...result});
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const refresh = async () => {
    setLoading(true);
    const result = await getBalancoPagamentos(alert);
    if (result) {
      setPagamentos({...result});
      alert.success('Dados Atualizados');
    }
    setLoading(false);
  };

  return (
    <Template loading={loading} onlyContent>
      <PageHeader tittle="Balanço de Pagamentos do Mês" min>
        <ButtonGroup className="float-right">
          <Button variant="primary" onClick={() => refresh()}>
            <FaSync />
          </Button>
        </ButtonGroup>
      </PageHeader>
      <Row>
        <Col>
          <Card bg={'success'} text={'white'} className="mb-2" >
            <Card.Header>Recebido</Card.Header>
            <Card.Body>
              <Card.Title>{formatMoney(pagamentos.totalRecebido)}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg={'danger'} text={'white'} className="mb-2" >
            <Card.Header>A Receber</Card.Header>
            <Card.Body>
              <Card.Title>{formatMoney(pagamentos.totalRestante)}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card bg={'secondary'} text={'white'} className="mb-2" >
            <Card.Header>Total</Card.Header>
            <Card.Body>
              <Card.Title>{formatMoney(pagamentos.totalRecebido + pagamentos.totalRestante)}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col>
            <PieChart chartName="Pagamentos"
              labels={['Recebido', 'A receber']}
              values={[pagamentos.totalRecebido, pagamentos.totalRestante]} />
        </Col>
        <Col></Col>
      </Row>
    </Template>
  );
}

export default BalancoPagamentos;