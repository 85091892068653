import React from 'react';
import {  Button } from 'react-bootstrap';

function ButtonSubmit(props) {
    return (
        <>
            <Button className="float-right" {...props}>{props.children}</Button>
        </>
    );
}

ButtonSubmit.defaultProps = {
    size: 'lg'
}

export default ButtonSubmit;