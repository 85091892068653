import React, { useEffect, useState } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col, Form } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import { getCompany, editCompany } from '../../../services/Companies';
import { getIdFromUrlLocationPathname } from '../../../helpers/functions';
import useForm from '../../../helpers/formHelper';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBar from '../../../components/molecules/ButtonBar';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import Session from '../../../components/atoms/Session';
import TableConfigurations from '../../../components/atoms/TableConfigurations';

function Edit(props) {

  const [loading, setLoading] = useState(true);
  const alert = useAlert();

  const [data, setData] = useState();
  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const { inputs, handleInputChange, setInputs, validateInputs } = useForm();

  const id = getIdFromUrlLocationPathname(props.history.location.pathname);

  const addValidationError = (validationError) => {
    let validationsError = validationErrors.slice();
    validationsError.push(validationError);
    setValidationErrors(validationsError);
  }

  const edit = async () => {
    setLoading(true);
    const response = await editCompany(id, {
      nome: inputs.nome,
      configuracoes: inputs.configuracoes
    }, alert, addValidationError);

    if (response) {
      props.history.push(`/company/detail/${id}`);
      alert.success('Empresa editada com sucesso');
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getCompany(id, alert);
      if (result) {
        setInputs({ ...result });
      }
      if (result && result.data) {
        setData(new Date(result.data));
      }
      setLoading(false);
    };

    fetchData();
  }, [id, setInputs]);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const errors = validateInputs({ page: 'editCompany', data });
      if (errors && errors.length > 0) {
        event.stopPropagation();
        setValidationErrors(errors);
      } else {
        edit();
      }
    }

    setValidated(true);
  };

  return (
    <Template validationErrors={validationErrors} clearErrosFunc={() => setValidationErrors([])}
      loading={loading}>
      <PageHeader tittle="Editar Empresa" />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <InputText id="nome" name="Nome" value={inputs.nome || ''}
              onChange={handleInputChange} />
          </Col>
        </Row>
        <Session name="Configurações">
          <TableConfigurations columns={[{ dataField: 'chave', text: 'Chave' }, { dataField: 'valor', text: 'Valor' }]}
            keyField='chave' data={inputs.configuracoes || []} editable />
        </Session>
        <ButtonBar>
          <ButtonSubmit variant="success" type="submit">Salvar</ButtonSubmit>
        </ButtonBar>
      </Form>
    </Template>
  );
}

export default Edit;