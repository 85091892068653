import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import Space from '../../atoms/VerticalSpace';

function Menu(props) {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
        <Navbar.Brand href="/">Agenda {props?.user?.empresa ? ' | ' + props?.user?.empresa?.nome : ''}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {!props.disableMenu &&
            <>
              <Nav className="mr-auto">
                <NavDropdown title="Compromissos" >
                  <NavDropdown.Item href="/task/new">Novo</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/tasks">Lista</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/task/inconsistents">Inconsistências</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Lembretes" >
                  <NavDropdown.Item href="/reminder/new">Novo</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/reminders">Lista</NavDropdown.Item>
                </NavDropdown>
                {(props.user?.perfil === "ADMIN_EMPRESA" || props.user?.perfil === "ADMIN") &&
                  <NavDropdown title="Minha Empresa">
                    <NavDropdown.Item href={`/users`}>Usuários</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/reports">Relatórios</NavDropdown.Item>
                  </NavDropdown>
                }
                {(props.user?.perfil === "ADMIN") &&
                  <NavDropdown title="Admin">
                    <NavDropdown.Item href="/companies">Empresas</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href={`/admin/users`}>Usuários</NavDropdown.Item>
                  </NavDropdown>
                }
              </Nav>
            </>
          }
          {(!props.disableUser && props.user) &&
            <>
              <Nav className="mr-auto">
              </Nav>
              <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                  <FaUser /> {`${props.user.nome}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/profile">Perfil</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="/logout">Sair</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          }
        </Navbar.Collapse>
      </Navbar>
      <Space size={3} />
    </>
  );
}

export default Menu;