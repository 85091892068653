import React from 'react';
import { Redirect } from 'react-router-dom';
import { logout } from "../../../helpers/authHelper";

function Logout() {

  logout();

  return (
    <Redirect to="/login" />
  );
}

export default Logout;