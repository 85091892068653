import React, { useState, useEffect } from 'react';
import Template from '../../../components/organisms/Template';
import { Row, Col } from 'react-bootstrap';
import InputText from '../../../components/atoms/InputText';
import Session from '../../../components/atoms/Session';
import { getReminder, removeReminder } from '../../../services/Reminders';
import { getIdFromUrlLocationPathname, isRemoveUrlLocation, formatDateTime, formatDate } from '../../../helpers/functions';
import VerticalSpace from '../../../components/atoms/VerticalSpace';
import ButtonSubmit from '../../../components/atoms/ButtonSubmit';
import ButtonBack from '../../../components/atoms/ButtonBack';
import { useAlert } from 'react-alert';
import PageHeader from '../../../components/molecules/PageHeader';
import InputTextArea from '../../../components/atoms/InputTextArea';
import ModalConfirmRemove from '../../../components/organisms/ModalConfirmRemove';

function Detail(props) {

  const [loading, setLoading] = useState(true);
  const [reminder, setReminder] = useState({});
  const id = getIdFromUrlLocationPathname(props.history.location.pathname);
  const isRemove = isRemoveUrlLocation(props.history.location);
  const alert = useAlert();

  const [confirmRemoveModalShow, setConfirmRemoveModalShow] = useState(isRemove);

  const remove = async () => {
    setLoading(true);
    const response = await removeReminder(id, alert);
    if (response) {
      props.history.push('/reminders');
      alert.success('Lembrete removido com sucesso');
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await getReminder(id, alert);
      if (result) {
        setReminder(result);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  return (
    <Template loading={loading}>
      <PageHeader tittle="Detalhes do Lembrete" />
      <Row>
        <Col>
          <InputText name="Data"
            value={reminder.data ? formatDate(reminder.data) : ''} disabled />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputTextArea name="Notas" value={reminder.nota || ''} disabled />
        </Col>
      </Row>
      <Session name="Informações">
        <Row>
          <Col>
            <InputText name="Criado Por" value={reminder?.responsavel?.nome || ''} disabled />
          </Col>
          <Col>
            <InputText name="Data Criação"
              value={reminder.dataHoraCriacao ? formatDateTime(reminder.dataHoraCriacao) : ''} disabled />
          </Col>
        </Row>
      </Session>
      <VerticalSpace />
      <Row>
        <Col style={{ paddingRight: '5px' }}>
          <ButtonBack />
        </Col>
        <Col className="col-md-1" style={{ paddingRight: '5px' }}>
          <ButtonSubmit className="center-block" variant="warning"
            onClick={() => { props.history.push(`/reminder/edit/${id}`) }}>Editar</ButtonSubmit>
        </Col>
        <Col style={{ paddingRight: '5px' }}>
          <ButtonSubmit className="float-right" variant="danger"
            onClick={() => { setConfirmRemoveModalShow(true) }}>Remover</ButtonSubmit>
        </Col>
      </Row>
      <ModalConfirmRemove show={confirmRemoveModalShow} onHide={() => setConfirmRemoveModalShow(false)}
        onConfirm={() => { remove() }} />
    </Template>
  );
}

export default Detail;