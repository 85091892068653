import React from 'react';

function VerticalSpace(props) {

    let spaces = [];
    for (let i = 0; i < props.size; i++) {
        spaces.push(<br key={i}/>);
    }

    return (
        <> 
            {spaces}
        </>
    );
}

VerticalSpace.defaultProps = {
    size: 1
}

export default VerticalSpace;