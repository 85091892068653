import React from 'react';
import { Tab, Card, Row, Col } from 'react-bootstrap';
import ContentHistory from '../ContentHistory';
import ContentHistoryDocs from '../ContentHistoryDocs';
import InputText from '../../../components/atoms/InputText';

function renderItemHistory(row, i) {
  let tipo = "";
  if(row) {
    tipo = row.tipo;
  }
  if (row && row.tipo === "CRIACAO") {
    tipo = "CRIAÇÃO";
  } else if (row && row.tipo === "ALTERACAO") {
    tipo = "ALTERAÇÃO";
  } else if (row && row.tipo === "REMOCAO") {
    tipo = "REMOÇÃO";
  }

  return (
    <Tab.Pane eventKey={i}>
      <Card className="fundo" variant="secondary">
        <Card.Body>
          <Row>
            <Col>
              <InputText name="Tipo" value={tipo || ''} disabled />
            </Col>
            <Col>
              <InputText name="Responsável" value={(row && row.responsavel && row.responsavel.nome) || ''} disabled />
            </Col>
          </Row>
          {row.tipo === "ALTERACAO" && <ContentHistory history={row} />}
          {row.tipo === "DOCUMENTO" && <ContentHistoryDocs adicionados={row.docsAdicionados} removidos={row.docsRemovidos} />}
        </Card.Body>
      </Card>
    </Tab.Pane>
  );
}

function ContentListHistory(props) {
  return (
    <Tab.Content>
      {props.rows && props.rows.map(renderItemHistory)}
    </Tab.Content>
  );
}

export default ContentListHistory;