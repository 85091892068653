import React from 'react';
import Template from '../../../components/organisms/Template';
import Session from '../../../components/atoms/Session';
import { removeUnauthorized } from '../../../helpers/authHelper';
import { Container } from 'react-bootstrap';

function Unauthorized() {

  removeUnauthorized();

  return (
    <Template>
      <Container>
        <Session>
          <h1>Ops!</h1>
          <p>Você não tem permissão para realizar essa ação...</p>
        </Session>
      </Container>
    </Template>
  );
}

export default Unauthorized;