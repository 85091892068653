import React from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

function ButtonBack(props) {

    const { staticContext, ...rest } = props;

    const goBack = () => {
        props.history.go(-1);
    };

    return (
        <>
            <Button {...rest} variant="secondary" size="lg" className="float-left" onClick={goBack}>Voltar</Button>
        </>
    );
}

export default withRouter(ButtonBack);