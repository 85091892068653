export const USER_KEY = "AEL_USER";
export const FORBIDDEN_KEY = "AEL_FORBIDDEN";

export const isAuthenticated = () => {
  return localStorage.getItem(USER_KEY) !== null;
};

export const isUnauthorized = () => {
  return localStorage.getItem(FORBIDDEN_KEY) !== null;
};

export const getUserSession = () => {
  if (isAuthenticated()) {
    return JSON.parse(localStorage.getItem(USER_KEY));
  } else {
    return undefined;
  }
};

export const login = (user) => {
  if (user && user.user && user.user.empresa && user.user.empresa._id) {
    let empresa = { _id: user.user.empresa._id, nome: user.user.empresa.nome, configuracoes: user.user.empresa.configuracoes };
    localStorage.setItem(USER_KEY, JSON.stringify({
      id: user.user._id,
      nome: user.user.nome,
      empresa: empresa,
      perfil: user.user.perfil,
      trocarSenha: user.user.trocarSenha,
      token: user.token
    }));
  } else {
    localStorage.setItem(USER_KEY, JSON.stringify({
      id: user.user._id,
      nome: user.user.nome,
      perfil: user.user.perfil,
      trocarSenha: user.user.trocarSenha,
      token: user.token
    }));
  }
};

export const logout = () => {
  localStorage.removeItem(USER_KEY);
};

export const changedPassword = () => {
  if (isAuthenticated()) {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    localStorage.setItem(USER_KEY, JSON.stringify({
      id: user._id,
      nome: user.nome,
      empresa: user.empresa,
      perfil: user.perfil,
      trocarSenha: false,
      token: user.token
    }));
  }
};

export const getConfigCompany = (chave) => {
  try {
    if (isAuthenticated()) {
      const user = JSON.parse(localStorage.getItem(USER_KEY));
      const configs = user.empresa.configuracoes;
      const config = configs.find(obj => (obj.chave === chave));
      return config ? config.valor : undefined;
    } else {
      return undefined;
    }
  } catch (error) {
    return undefined;
  }
};

export const setUnauthorized = () => {
  localStorage.setItem(FORBIDDEN_KEY, 'true');
};

export const removeUnauthorized = () => {
  localStorage.removeItem(FORBIDDEN_KEY);
};